import React, { useContext, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

import "../assets/css/modal.css";

//Contexto
import ConexContext from "../context/conex/ConexContext";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";

import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";

const MisCursosMod = (props) => {
	const { loc, peticion } = useContext(ConexContext);
	const [Cursos_AUX, setCursos_AUX] = useState(null); //cursos asistidos y pagados del asociado
	const { lang } = useParams();

	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "fecha DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + " DESC" });
		else setListParams({ ...ListParams, orden: campo });
	};

	//cierra el modal
	function cierra() {
		setCursos_AUX(null);
		props.onHide();
	}

	//useEffect
	useEffect(() => {
		const ver = async () => {
			//cursos del asociado
			peticion("/cursos/listCursos?idAsoc=" + props.show).then((pet) => {
				if (pet.estado === 0) setCursos_AUX(pet?.res ?? []);
			});
		};
		if (props.show > 0) ver();
		// eslint-disable-next-line
	}, [props.show]);

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper mis-cursos">
					<div className="modal-header">
						<div className="flex-wrapper">
							<h3>{loc("Mis cursos")}</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div className="backdrop cursos-overview">
						<div className="content-wrapper">
							{Cursos_AUX && Cursos_AUX.length > 0 ? (
								<>
									<div className="table-container">
										<table className="table-miscursos">
											<thead className="table-headers">
												<tr>
													<th className="wide-column double-grow-column">
														<button onClick={() => orden("titulo")}>
															{loc("Título")}
														</button>
														<ul className="listaordena">
															{ListParams.orden === "titulo" && (
																<li>
																	<img src={asc} alt="ordena ascendente" />
																</li>
															)}
															{ListParams.orden === "titulo DESC" && (
																<li>
																	<img src={desc} alt="ordena descendente" />
																</li>
															)}
														</ul>
													</th>
													<th className="medium-column grow-column">
														<button onClick={() => orden("fecha")}>
															{loc("Fecha")}
														</button>
														<ul className="listaordena">
															{ListParams.orden === "fecha" && (
																<li>
																	<img src={asc} alt="ordena ascendente" />
																</li>
															)}
															{ListParams.orden === "fecha DESC" && (
																<li>
																	<img src={desc} alt="ordena descendente" />
																</li>
															)}
														</ul>
													</th>
													<th className="medium-column grow-column">
														<button onClick={() => orden("ciudad")}>
															{loc("Ciudad")}
														</button>
														<ul className="listaordena">
															{ListParams.orden === "ciudad" && (
																<li>
																	<img src={asc} alt="ordena ascendente" />
																</li>
															)}
															{ListParams.orden === "ciudad DESC" && (
																<li>
																	<img src={desc} alt="ordena descendente" />
																</li>
															)}
														</ul>
													</th>
													<th className="medium-column grow-column">
														<button onClick={() => orden("dojo")}>
															{loc("Organiza")}
														</button>
														<ul className="listaordena">
															{ListParams.orden === "dojo" && (
																<li>
																	<img src={asc} alt="ordena ascendente" />
																</li>
															)}
															{ListParams.orden === "dojo DESC" && (
																<li>
																	<img src={desc} alt="ordena descendente" />
																</li>
															)}
														</ul>
													</th>
												</tr>
											</thead>
											<tbody className="table-results">
												{Cursos_AUX.map((elem, index) => (
													<Fragment key={index}>
														<tr>
															<td className="wide-column double-grow-column">{elem.titulo}</td>
															<td className="medium-column grow-column">
																{new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																}).format(new Date(elem.fechaini))}{" "}
																-{" "}
																{new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																	month: "short",
																	year: "numeric",
																})
																	.format(new Date(elem.fechafin))
																	.toUpperCase()}
															</td>
															<td className="medium-column grow-column">{elem.ciudad}</td>
															<td className="medium-column grow-column">{elem.nombre}</td>
														</tr>
													</Fragment>
												))}
											</tbody>
										</table>
									</div>
								</>
							) : (
								<p className="proximamente">{loc("No tienes cursos")}</p>
							)}
						</div>
					</div>
				</div>
			</main>
		);
};

export default MisCursosMod;
