import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import validErr from "../assets/js/valida";

import "../assets/css/modal.css";

//Contexto
import ConexContext from "../context/conex/ConexContext";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as CuotaIcon } from "../assets/img/iconos/acae_fees.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";

//imágenes
import desc from "../assets/img/desc_gris.svg";
import asc from "../assets/img/asc_gris.svg";

const RemesasMod = (props) => {
	const { peticion, loc, Cargando } = useContext(ConexContext);
	const navigate = useNavigate();
	const { lang } = useParams();

	//atajos de teclado
	useHotkeys("alt+g", guarda, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: props.show !== 0 && !Cargando,
	});
	useHotkeys("esc", cierra, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: props.show !== 0 && !Cargando,
	});

	//STATES
	const [Filtro, setFiltro] = useState(""); // Filtro
	const [Anio, setAnio] = useState(""); // selec de años
	const [NumAd, setNumAd] = useState(0); // cuotas de adultos
	const [NumNi, setNumNi] = useState(0); // cuotas de niños
	const [ListParams, setListParams] = useState({
		orden: "numAcae",
		anio: "",
		selec: [],
		recarga: false,
	});
	const [List, setList] = useState([]); // listo alumnos
	const [Lectura, setLectura] = useState(false); //está en modo lectura

	const [ErrAnio, setErrAnio] = useState(""); // Error en el nombre
	const [ErrList, setErrList] = useState(""); // Error en el listado

	//REFs
	const rAnio = useRef();
	const rNumAd = useRef();
	const rNumNi = useRef();
	const rFiltr = useRef();

	//quita o pone un elemento del Select
	const toggleSelec = (val) => {
		if (Lectura) return;
		const a = [...ListParams.selec];
		var index = a.indexOf(val);
		if (index === -1) a.push(val);
		else a.splice(index, 1);
		setListParams((prev) => ({ ...prev, selec: a }));
	};
	//EFFECT
	useEffect(() => {
		async function ver() {
			const pet = await peticion("/cuotas/ver?id=" + props.show);
			if (pet.estado)
				//algún error
				return;
			try {
				setListParams((prev) => ({
					...prev,
					selec: pet.res.selec,
					anio: pet.res.anio,
					recarga: true,
				}));
				rAnio.current.focus();
				if (pet.res.cfValDojo !== 0) setLectura(true);
			} catch (e) {}
		}
		// console.log('props.show',props.show);
		if (props.show > 0) ver();
		else if (props.show < 0) {
			setAnio("");
			setNumAd(0);
			setNumNi(0);
			setListParams((prev) => ({
				...prev,
				anio: "",
			}));
			rAnio.current.focus();
		}
		// eslint-disable-next-line
	}, [props.show]);
	// useEffect(() => {console.log('List',List)}, [List]);
	// ha cambiado el año: hay que consultar los alumnos
	useEffect(() => {
		async function listAlum() {
			const pet = await peticion(
				`/cuotas/listAlum?anio=${Anio}&orden=${ListParams.orden}&id=${props.show}`
			);
			if (pet?.estado ?? true)
				//algún error
				return;
			setList(pet?.res ?? []);
			setListParams((prev) => ({
				...prev,
				recarga: false,
			}));
		}
		if (ListParams.recarga && Anio !== ListParams.anio) {
			//Se quiere cargar el listado
			setAnio(ListParams.anio);
			return; //se volverá a llegar y ya listar directamente
		}
		if (Anio === "") {
			//el usuario ha seleccionado sin año
			setList([]);
			return;
		}
		if (Anio !== ListParams.anio)
			setListParams((prev) => ({
				...prev,
				selec: [],
				anio: Anio,
				recarga: true,
			}));
		else if (ListParams.recarga) listAlum();
		// eslint-disable-next-line
	}, [Anio, ListParams, props.show]);
	useEffect(() => {
		if (props.show === 0) return;
		let nA = 0;
		let nN = 0;
		List.forEach((e) => {
			if (ListParams.selec.includes(e.idAso)) {
				if (e.tipo === "A") nA++;
				else nN++;
			}
		});
		setNumAd(nA);
		setNumNi(nN);
	}, [List, ListParams.selec, props.show]);
	// guardar el registro
	async function guarda() {
		let error = false;
		//requeridos
		error |= validErr(Anio === "", setErrAnio, loc("El año es requerido."));
		error |= validErr(
			List.length > 0 && ListParams.selec.length === 0,
			setErrList,
			loc("Debes marcar al menos un asociado.")
		);
		// filtros sin necesidad de peticiones
		if (error)
			//algún error
			return;
		let pet;
		if (props.show < 0)
			//inserción
			pet = await peticion("/cuotas/ins", {
				method: "POST",
				json: {
					anio: Anio,
					selec: ListParams.selec,
				},
			});
		else if (props.show > 0)
			//modificación
			pet = await peticion("/cuotas/mod", {
				method: "POST",
				json: {
					id: props.show,
					anio: Anio,
					selec: ListParams.selec,
				},
			});
		let id = pet?.res?.id ?? props.show;
		if (pet.estado)
			//algún error
			return;
		cierra();
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev) => ({ ...prev }));
		else {
			navigate(`/${lang}/app/cuotas/${id}`);
		}
	}
	//cierra el modal
	function cierra() {
		setErrAnio("");
		setErrList("");
		setList([]);
		setAnio("");
		setLectura(false);
		props.onHide();
	}
	//cierra el modal
	function orden(campo) {
		if (ListParams.orden === campo) campo = campo + " DESC";
		setListParams({
			...ListParams,
			orden: campo,
			recarga: true,
		});
	}

	List.sort((a, b) => a.selec - b.selec);

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<CuotaIcon className="section-icon cuotas" />
							<h3>
								{props.show > 0 ? loc("Modificar remesa") : loc("Nueva remesa")}
							</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div className="modal-content-scroll form-container">
						<div className="modal-content-outer-wrapper">
							<div className="flex-wrapper padding-bottom">
								<div className="flex-row-item full-width">
									<div className="equal-width relative-container">
										<p>{loc("Año")}:</p>
										<select
											className="equal-width"
											disabled={Lectura}
											ref={rAnio}
											value={Anio}
											onChange={() => {
												setErrAnio("");
												setAnio(rAnio.current.value);
											}}>
											<option value={""}>{loc("Seleccionar año")}</option>
											{Array(50)
												.fill(new Date().getFullYear() + 1)
												.map((elem, i) => (
													<option value={elem - i} key={i}>
														{elem - i}
													</option>
												))}
										</select>
										{ErrAnio ? <p className="error">{ErrAnio}</p> : ""}
									</div>
									<div className="equal-width">
										<p>{loc("Número de cuotas de adultos")}:</p>
										<input
											style={{ textAlign: "right" }}
											disabled
											ref={rNumAd}
											value={NumAd}
										/>
									</div>
									<div className="equal-width">
										<p>{loc("Número de cuotas de niños")}:</p>
										<input
											disabled
											style={{ textAlign: "right" }}
											ref={rNumNi}
											value={NumNi}
										/>
									</div>
								</div>
							</div>
							<div className='relative-container'>
								<input
									ref={rFiltr}
									placeholder={loc("Filtrar")}
									onChange={() => setFiltro(rFiltr.current.value)}
									value={Filtro}
								/>
								<p className="error"></p>
							</div>
						</div>
						<div className="table-container">
							<table className="table-cuotas-mod">
								<thead className="table-headers">
									<tr key="cabeceradatostabla" style={{ width: "100%" }}>
										{/* <th style={{ width: '20%' }}>
											<button onClick={() => orden('numAcae')}>{loc('Núm. ACAE')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'numAcae' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'numAcae DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th> */}
										<th style={{ width: "20%" }}>
											<button onClick={() => orden("nombre")}>
												{loc("Nombre")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "nombre" && (
													<li>
														<img src={asc} alt="ordena ascendente" />
													</li>
												)}
												{ListParams.orden === "nombre DESC" && (
													<li>
														<img src={desc} alt="ordena descendente" />
													</li>
												)}
											</ul>
										</th>
										<th style={{ width: "40%" }}>
											<button onClick={() => orden("apellido")}>
												{loc("Apellidos")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "apellido" && (
													<li>
														<img src={asc} alt="ordena ascendente" />
													</li>
												)}
												{ListParams.orden === "apellido DESC" && (
													<li>
														<img src={desc} alt="ordena descendente" />
													</li>
												)}
											</ul>
										</th>
										<th style={{ width: "10%" }}>
											<button onClick={() => orden("tipo")}>
												{loc("Tipo")}
											</button>
											<ul className="listaordena">
												{ListParams.orden === "tipo" && (
													<li>
														<img src={asc} alt="ordena ascendente" />
													</li>
												)}
												{ListParams.orden === "tipo DESC" && (
													<li>
														<img src={desc} alt="ordena descendente" />
													</li>
												)}
											</ul>
										</th>
										{/* {!Lectura &&
											<th style={{ width: '10%', textAlign: 'right' }}>
												<button disabled onClick={() => orden('numAcae')}>{loc('Selecciona')}</button>
											</th>
										} */}
									</tr>
								</thead>
								<tbody className="table-results">
									{List.filter((elem) =>
										` ${elem?.numAcae ?? ""} ${elem?.nombre ?? ""} ${
											elem?.apellido ?? ""
										}`
											.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
											.includes(Filtro.toUpperCase())
									).map((elem) =>
										Lectura & !ListParams.selec.includes(elem.idAso) ? null : (
											<tr
												key={elem.idAso}
												className={
													elem.selec
														? "not-clickable"
														: ListParams.selec.includes(elem.idAso)
														? "clickable selected"
														: "clickable"
												}
												onClick={() => {
													if (!elem.selec) toggleSelec(elem.idAso);
												}}>
												{/* <td className="avatar-row" style={{ width: '20%' }}>
													<img src={'/avatars/' + (elem.avatar ?? 'default.jpg')} />
													<span>{elem.numAcae}</span>
												</td> */}

												<td className='table-button black ocultamovil' style={{ width: "20%" }}>
													<div className='flex-row-item gap-small'>
														<Link to={`/${lang}/app/asociados/usuario/${elem.numAcae}`} className='cell-td-button' title={loc('Ir a asociado')}>
															<ViewIcon />
														</Link>
														<span className="cell-td-button--label grow text-ellipsis">
															{elem?.nombre}
														</span>
													</div>
												</td>
												<td style={{ width: "40%" }}>{elem.apellido}</td>
												<td style={{ width: "10%" }}>
													{elem.tipo === "A" ? loc("Adulto") : loc("Niño")}
												</td>
												{/* {!Lectura &&
													<td style={{ width: '10%' }}>
														<div>
															{elem.selec ? loc('Ya remesado') : ListParams.selec.includes(elem.idAso) ?
																<img src={pin} alt="" className='imgpin' />
																:
																<img src={nopin} alt="" className='imgpin' />
															}
														</div>
													</td>
												} */}
											</tr>
										)
									)}
								</tbody>
							</table>
							<div>
								<p className="error">{ErrList}</p>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<div className="flex-wrapper flex-end">
							{!Lectura && (
								<div className="flex-wrapper flex-end">
									<button
										type="button"
										onClick={guarda}
										className="button primary ">
										{loc("Aplicar")}
										<GuardarIcon alt={loc('Aplicar')} title={loc('Aplicar')} />
									</button>
									<p className="error"></p>
								</div>
							)}
							<button className="button tertiary" onClick={cierra}>
								{loc("Cancelar")}
							</button>
						</div>
					</div>
				</div>
			</main>
		);
};

export default RemesasMod;
