import React, { useState, useEffect, useRef, useContext } from "react";
import "../assets/css/modal.css";
import ReactCrop, {
	centerCrop,
	makeAspectCrop
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './Canvas'

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
}

const CursoCartelMod = (props) => {
	const [imgSrc, setImgSrc] = useState('');
	const [crop, setCrop] = useState(null);
	const [completedCrop, setCompletedCrop] = useState(null);

	const {
		loc
	} = useContext(ConexContext); //contexto

	const fileInputRef = useRef();
	const imgRef = useRef();
	const previewCanvasRef = useRef();

	const scale = 1;
	const rotate = 0;
	const aspect = 210 / 297;

	// effect para Imagen
	useEffect(() => {
		if ( completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current ) {
			// We use canvasPreview as it's much faster than imgPreview.
			canvasPreview(
				imgRef.current,
				previewCanvasRef.current,
				completedCrop,
				scale,
				rotate,
			)
		}
	}, [completedCrop])

	//cierra el modal
	function cierra() {
		setCompletedCrop(null);
		setImgSrc('');
		props.onHide();
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				setImgSrc(reader.result?.toString() || "");
				// setFormu({ ...Formu, imagen: reader.result?.toString() || ''})
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	async function GuardarImagen() {
		const image = imgRef.current;
		const previewCanvas = previewCanvasRef.current;
		if (!image || !previewCanvas || !completedCrop) {
			throw new Error('Crop canvas does not exist');
		}
		// const scaleX = image.naturalWidth / image.width;
		// const scaleY = image.naturalHeight / image.height;
		// Crear un canvas normal (en lugar de OffscreenCanvas)
		const canvas = document.createElement('canvas');
		// canvas.width = completedCrop.width * scaleX;
		// canvas.height = completedCrop.height * scaleY;
		canvas.width = 1273;
		canvas.height = 1800;
		const ctx = canvas.getContext('2d');
		if (!ctx) {
			throw new Error('No 2d context');
		}
		ctx.drawImage(
			previewCanvas,
			0,
			0,
			previewCanvas.width,
			previewCanvas.height,
			0,
			0,
			canvas.width,
			canvas.height
		);
		// Convertir la imagen a Base64
		const base64Image = canvas.toDataURL('image/png'); // Cambiar 'image/jpeg' si necesitas otro formato
		props.setImagen(base64Image);
		cierra();
	}

	if (props.show === "cursoCerrado") return "";
	else
		return (
			<div className={`img-curso-wrapper ${props.show}`}>
				<div className="img-curso-container">
					<div
					className="upload-curso-img"
					onChange={onSelectFile}
					ref={fileInputRef}>
						{
						!imgSrc && 
							<div>
								<label
									htmlFor="curso-img-input"
									className="upload-curso-img__label">
									{loc("Subir imagen")}
								</label>
								<input
									className="upload-curso-img__input"
									type="file"
									accept="image/*"
									onClick={() => {}}
									id="curso-img-input"
								/>
							</div>
						}
					</div>
					
					{!!imgSrc && (
						<ReactCrop
							crop={crop}
							onChange={(_, percentCrop) => setCrop(percentCrop)}
							onComplete={(c) => {
								setCompletedCrop(c);
							}}
							aspect={aspect}>
							<img
								className="upload-curso-img__select"
								ref={imgRef}
								alt="Crop me"
								src={imgSrc}
								style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
								onLoad={onImageLoad}
							/>
						</ReactCrop>
					)}
					{!!completedCrop ? (
						<div className='upload-curso-img__preview'>
							<canvas
							ref={previewCanvasRef}
							style={{
								border: '1px solid black',
								objectFit: 'contain',
								width: completedCrop.width,
								height: completedCrop.height,
							}}
							/>
						</div>
					) : (
						<div className="upload-curso-img__placeholder">{loc("Sin imagen")}</div>
					)}
					<button className="cta avatar-cerrar">
						<CloseIcon
							onClick={cierra}
							alt={loc("Cerrar ventana")}
							title="(ESC)"
						/>
					</button>
					{imgSrc && 
						<button
							className='button cta'
							onClick={() => {
								GuardarImagen();
								// cierra();
							}}>
							{loc('Aplicar')}
						</button>
					}
					
				</div>
				<div className='img-curso-container__bg'></div>
			</div>
		);
};

export default CursoCartelMod;
