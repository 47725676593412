import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useHotkeys } from 'react-hotkeys-hook';

// Contexto
import ConexContext from "../context/conex/ConexContext";

// SVGs
import { ReactComponent as WriteIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as AsociadoIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as AsocIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as AdminIcon } from "../assets/img/iconos/acae_flower.svg";

// Imágenes
// import loadingGif from "../assets/img/loading.gif";
import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";

// Módulos
import Paginacion from './comun/Paginacion';
import AsociadoMod from './AsociadosMod';
import MenuToggle from './comun/menuToggle';

const Asociados = ({ setSidebar, Sidebar }) => {
    const { peticion, loc, perfil_admin, perfil_adm_asoc, perfil_adm_dojo, Cargando } = useContext(ConexContext);
    const { lang, idReg, dojo, acc } = useParams(); // Captura `lang` directamente de la URL

    // Estados
    const [VerMod, setVerMod] = useState(0); // para ver el modal de ficheros
    const [List, setList] = useState([]); // listado
    const [NRes, setNRes] = useState(0); // nº de registros con filtro
    const [NTot, setNTot] = useState(0); // nº de registros sin filtro
    const [ListParams, setListParams] = useState({
        num: 15,
        orden: 'numAcae',
        filtro: '',
        ini: 0,
        idDojo: 0,
        nomDoj: '',
        cfAsoc: 0,
        idReg: 0
    });

    // Atajos de teclado
    useHotkeys('alt+n', () => { if (perfil_admin || perfil_adm_asoc || perfil_adm_dojo) setVerMod(-1); });

    // Borra un registro concreto
    const borrar = async (id) => {
        const pet = await peticion('/asociados/del', {
            method: 'POST',
            json: { id }
        });
        if (pet?.estado === 9) {
            Swal.fire({
                title: loc("El asociado no se puede eliminar."),
                text: loc("El asociado tiene un historial asignado, por lo que no puede ser eliminado"),
                showConfirmButton: true,
                icon: 'error',
            });
            return;
        }
        if (pet.estado) return;

        Swal.fire({
            title: "Asociado eliminado.",
            showConfirmButton: false,
            icon: 'success',
            timer: 1500
        });
        setListParams({ ...ListParams });
    };

    // Cambia el orden
    const orden = (campo) => {
        if (ListParams.orden === campo)
            setListParams({ ...ListParams, orden: campo + ' DESC', idReg: 0 });
        else
            setListParams({ ...ListParams, orden: campo, idReg: 0 });
    };

    useEffect(() => {
        const listar = async () => {
            const pet = await peticion('/asociados/?' +
                `ini=${ListParams.ini}&` +
                `pag=${ListParams.num}&` +
                `orden=${ListParams.orden}&` +
                `filtro=${ListParams.filtro}&` +
                `idDojo=${ListParams.idDojo}`
            );
            if (pet?.estado) return null;
            setList(pet.res);
            setNRes(pet.nRes);
            setNTot(pet.nTot);
        };

        const getDojo = async (id) => {
            const pet = await peticion('/dojos/ver?id=' + id);
            if (pet?.estado) return;
            setListParams((prev) => ({
                ...prev,
                idDojo: pet?.res?.idDojo ?? id,
                cfAsoc: pet?.res?.cfAsoc ?? 0,
                nomDoj: pet?.res?.nombre ?? ''
            }));
        };

        const getPosicion = async (id) => {
            const pet = await peticion('/asociados/posicion?' +
                `id=${id}&` +
                `orden=${ListParams.orden}&` +
                `filtro=${ListParams.filtro}&` +
                `idDojo=${ListParams.idDojo}`
            );
            if (pet?.estado) return;
            if (pet?.res?.pos) {
                const pos = pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1;
                if (pos !== ListParams.ini) {
                    setListParams((prev) => ({
                        ...prev,
                        ini: pos,
                        idReg: id
                    }));
                } else {
                    setListParams((prev) => ({ ...prev, idReg: id }));
                }
            } else {
                listar();
            }
        };

        let id = parseInt(dojo?.split('_')[1]);
        const idDojo = isNaN(id) ? 0 : id;
        const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg);

        if (ListParams.idDojo !== idDojo)
            getDojo(idDojo);
        else if (ListParams.idReg !== idRegN)
            getPosicion(idRegN);
        else {
            listar();
            if (acc === 'mod') setVerMod(idReg);
        }
    }, [ListParams, dojo, idReg, acc, peticion]);

    const mostrarCampos = true;

    return (
        <>
            <div className='content-wrapper main-section' id='asociados'>
                <header className='backdrop space-between'>
                    <div className='flex-row-item'>
                        <AsociadoIcon className='section-icon' alt='Asociados' />
                        <h1>{loc('Asociados' + (ListParams.nomDoj ? " de " + ListParams.nomDoj : ""))}</h1>
                    </div>
                    <MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />

                </header>

                {Cargando && !VerMod ? (
					<div className="backdrop precarga">
                    <div className="loader-wrapper">
                        <div className="circle-wrapper">
                            <div className="circle-1" />
                            <div className="circle-2" />
                            <div className="circle-3" />
                            <div className="circle-4" />
                            <div className="circle-5" />
                        </div>
                        <AcaeIcon />
                    </div>
                    <span>
                        {loc("Cargando...")}
                    </span>
                </div>
				) : (
                    <div className="backdrop content-wrapper">
                        <div className="filtering">
                            <Paginacion
                                nrPag={List.length}
                                NRes={NRes}
                                NTot={NTot}
                                Ini={ListParams.ini}
                                setListParams={setListParams}
                                Num={ListParams.num}
                                Filtro={ListParams.filtro}
                            />
                        </div>
                        <div className="table-container">
                            <table className='table-asociados'>
                                <thead className='table-headers'>
                                    <tr>
                                        <th className='wide-column'>
                                            <button onClick={() => orden('numAcae')}>{loc('Num ACAE')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'numAcae' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'numAcae DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        <th className='medium-column'>
                                            <button>{loc('Admin')}</button>
                                        </th>
                                        <th className='ocultamovil wide-column grow-column'>
                                            <button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'nombre' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'nombre DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        <th className='ocultamovil'>
                                            <button onClick={() => orden('apellido')}>{loc('Apellidos')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'apellido' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'apellido DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        <th>
                                            <button onClick={() => orden('mail')}>{loc('Correo')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'mail' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'mail DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        <th className='ocultamovil'>
                                            <button onClick={() => orden('dojo')}>{loc('Dojo')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'dojo' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'dojo DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        <th className='ocultamovil medium-column'>
                                            <button onClick={() => orden('edad')}>{loc('Tipo')}</button>
                                            <ul className="listaordena">
                                                {ListParams.orden === 'edad' &&
                                                    <li><img src={asc} alt='ordena ascendente' /></li>
                                                }
                                                {ListParams.orden === 'edad DESC' &&
                                                    <li><img src={desc} alt='ordena descendente' /></li>
                                                }
                                            </ul>
                                        </th>
                                        {mostrarCampos && (
                                            <th className='ocultamovil table-actions hide-text'>Ac</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className='table-results'>
                                    {List.map((elem) => (
                                        <Fragment key={elem.idAso}>
                                            <tr className={parseInt(elem.idAso) === parseInt(ListParams.idReg) ? 'selected' : ''}>
                                                <td className="avatar-row wide-column">
                                                    <img src={'/avatars/' + (elem.avatar ?? 'default.jpg')} alt=''/>
                                                    <span>{elem.numAcae}</span>
                                                </td>
                                                <td className='medium-column'>
                                                    <div className='iconos-usuario-container flex-row-column'>
                                                        {elem.esAdminDojo &&
                                                            <DojoIcon alt='Validar'
                                                                className='validated'
                                                                title={loc('Administrador del dojo')}
                                                            />
                                                        }
                                                        {elem.esAdminAsoc &&
                                                            <AsocIcon alt='Validar'
                                                                className='validated'
                                                                title={loc('Administrador de asociación')}
                                                            />
                                                        }
                                                        {elem.esAdmin &&
                                                            <AdminIcon alt='Validar'
                                                                className='validated'
                                                                title={loc('Administrador')}
                                                            />
                                                        }
                                                    </div>
                                                </td>
                                                <td className='table-button wide-column grow-column black ocultamovil'>
                                                    <div className='flex-row-item gap-small'>
                                                        <Link to={`/${lang}/app/asociados/usuario/${elem.numAcae}`} className='cell-td-button' title={loc('Ir a asociado')}>
                                                            <ViewIcon />
                                                        </Link>
                                                        <span className="cell-td-button--label grow text-ellipsis">
                                                            {elem?.nombre}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className='ocultamovil'>{elem.apellido}</td>
                                                <td><p>{elem.mail}</p>
                                                    <div className='detalles'>
                                                        <button><img src={despliega} alt="despliega" /></button>
                                                        <button><img src={pliega} alt="pliega" /></button>
                                                    </div>
                                                </td>
                                                <td className="table-button black ocultamovil">
												{perfil_admin || perfil_adm_asoc ? (
                                                    <div className='flex-row-item gap-small'>
                                                        <Link to={{ pathname: `/${lang}/app/asociados/dojo_${elem.codigo_dojo}` }} className='cell-td-button' title={loc('Ir a Dojo')}>
                                                            <ViewIcon />
                                                        </Link>
                                                        <span className="cell-td-button--label grow text-ellipsis">
                                                            {elem?.dojo}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {elem?.dojo}
                                                    </span>
                                                )}
												</td>
                                                <td className='ocultamovil medium-column'>{
                                                    elem.edad === 'A' ?
                                                        loc('Adulto')
                                                        :
                                                        loc('Niño')
                                                }</td>
                                                {mostrarCampos && (
                                                    <td className='ocultamovil table-actions'>
                                                        <ul className="flex-row-item no-gap">
                                                            <li>
                                                                <WriteIcon onClick={() => setVerMod(elem.idAso)} />
                                                            </li>
                                                            {(perfil_admin || perfil_adm_asoc || perfil_adm_dojo) &&
                                                                <li>
                                                                    <TrashIcon alt='trash' onClick={() => {
                                                                        Swal.fire({
                                                                            title: loc("¿Seguro que quieres eliminar el asociado?"),
                                                                            showCancelButton: true,
                                                                            confirmButtonText: loc("Sí"),
                                                                            icon: 'question',
                                                                            cancelButtonText: loc('No')
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) borrar(elem.idAso)
                                                                        });
                                                                    }} />
                                                                </li>
                                                            }
                                                        </ul>
                                                    </td>
                                                )}
                                            </tr>
                                        </Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {(perfil_admin || perfil_adm_asoc || perfil_adm_dojo) ? (
                            <div>
                                <button className="cta" onClick={() => setVerMod(-1)}>
                                    <span>
                                        {ListParams?.cfAsoc ?? false ?
                                            loc('Añadir nuevo asociado a: ') + (ListParams?.nomDoj ?? '')
                                            :
                                            loc('Añadir nuevo asociado')
                                        }
                                    </span>
                                    <MasIcon alt={loc('Más')} />
                                </button>
                            </div>
                        ) : null}
                        <AsociadoMod
                            show={VerMod}
                            ListParams={ListParams}
                            setListParams={setListParams}
                            onHide={() => setVerMod(0)}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Asociados;
