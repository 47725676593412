import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "react-avatar-edit";

import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import Swal from "sweetalert2";

import "../assets/css/modal.css";
import validErr from "../assets/js/valida";
//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as AsociadoIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as LinkIcon } from "../assets/img/iconos/acae_link.svg";
import { ReactComponent as LockIcon } from "../assets/img/iconos/acae_lock.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as NewimgIcon } from "../assets/img/iconos/acae_new_img.svg";

const AsociadoMod = (props) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_dojo,
		perfil_asoc,
		Cargando,
	} = useContext(ConexContext);
	const navigate = useNavigate();
	const { lang, acc } = useParams();

	//atajos de teclado
	useHotkeys("alt+g", guardarRegistro, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: props.show !== 0 && !Cargando,
	});
	useHotkeys("esc", cierra, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: props.show !== 0 && !Cargando,
	});

	//REFs
	const rId = useRef();
	const rDNI = useRef();
	const rNombre = useRef();
	const rApellido = useRef();
	const rMail = useRef();
	const rNumACAE = useRef();
	const rFecNacimiento = useRef();
	const rDomicilio = useRef();
	const rCiudad = useRef();
	const rProvincia = useRef();
	const rTelefono = useRef();
	const rObs = useRef();
	const rAsoc = useRef();
	const rDojo = useRef();
	const rBotAdmin = useRef();
	const rBotAdmAs = useRef();
	const rBotAdmDo = useRef();

	//STATES
	const [Imagen, setImagen] = useState(null); //imagen del avatar
	const [ClassAvatar, setClassAvatar] = useState("avCerrado"); //clase para el crop del avatar
	const [Asociaciones, setAsociaciones] = useState([]); //clase con las asociaciones
	const [Dojos, setDojos] = useState([]); //clase con los dojos
	const [IdAsociacion, setIdAsociacion] = useState(0); //asociación del select
	const [Mail, setMail] = useState(""); //mail
	const [IdDojo, setIdDojo] = useState(0); //dojo del select
	const [EsAdmin, setEsAdmin] = useState(false); //administrador
	const [EsAdmAs, setEsAdmAs] = useState(false); //administrador asociación
	const [EsAdmDo, setEsAdmDo] = useState(false); //administrador dojo
	const [RutAvatar, setRutAvatar] = useState(null); //ruta del avatar

	const [ErrDNI, setErrDNI] = useState(""); //Error en el DNI
	const [ErrNombre, setErrNombre] = useState(""); //Error en el nombre
	const [ErrApellido, setErrApellido] = useState(""); //Error en el apellido
	const [ErrMail, setErrMail] = useState(""); //Error en el correo
	const [ErrNACAE, setErrNACAE] = useState(""); //Error en numAcae
	const [ErrFecha, setErrFecha] = useState(""); //Error en la fecha
	const [ErrDomicilio, setErrDomicilio] = useState(""); //Error en el domicilio
	const [ErrCiudad, setErrCiudad] = useState(""); //Error en la ciudad
	const [ErrProvincia, setErrProvincia] = useState(""); //Error en la provincia
	const [ErrTelefono, setErrTelefono] = useState(""); //Error en el telefono
	const [ErrAsociacion, setErrAsociacion] = useState(""); //Error en el asociación
	const [ErrObs, setErrObs] = useState(""); //Error en la observación
	const [ErrDojo, setErrDojo] = useState(""); //Error en el Dojo
	const [nombreCompleto, setNombreCompleto] = useState(""); // Estado para almacenar el nombre del asociado
	const [Validado, setValidado] = useState(""); // Tiene la clave validada?
	const [Clave, setClave] = useState(""); // Clave para la validación

	//EFFECT
	useEffect(() => {
		//inicial
		async function ver() {
			const pet = await peticion("/asociados/ver?id=" + props.show);
			if (pet.estado)
				//algún error
				return;
			try {
				rId.current.value = pet.res.idAso;
				rDNI.current.value = pet.res.dni;
				rNombre.current.value = pet.res.nombre;
				rApellido.current.value = pet.res.apellido;
				rMail.current.value = pet.res.mail;
				rNumACAE.current.value = pet.res.numAcae;
				rFecNacimiento.current.value = pet.res.fechaNacimiento;
				rDomicilio.current.value = pet.res.domicilio;
				rCiudad.current.value = pet.res.ciudad;
				rProvincia.current.value = pet.res.provincia;
				rTelefono.current.value = pet.res.telefono;
				rObs.current.value = pet.res.obs;
				setNombreCompleto(pet.res.nombreCompleto);
				setIdDojo(pet.res?.cfDojo ?? 0);
				setIdAsociacion(pet.res?.cfAsoc ?? 0);
				setEsAdmin(pet.res.esAdmin);
				setEsAdmDo(pet.res.esAdminDojo);
				setEsAdmAs(pet.res.esAdminAsoc);
				setValidado(pet.res.validado);
				setClave(pet?.res?.clave ?? "");
				setMail(pet?.res?.mail ?? "");
				setRutAvatar(pet?.res?.avatar ?? null);
				rMail.current.focus();
				rMail.current.select();
			} catch (e) {}
		}
		async function verAsocs() {
			//descargo las Asociaciones
			const pet = await peticion("/asocs/?ini=0&pag=1000&orden=nombre&filtro=");
			if (pet.estado)
				//algún error
				return;
			setAsociaciones(pet?.res ?? []);
		}
		if (props.show === 0) return;
		verAsocs();
		if (props.show > 0) ver();
		else if (props.show < 0) {
			//insertando
			rId.current.value = "";
			rDNI.current.value = "";
			rNombre.current.value = "";
			rApellido.current.value = "";
			rMail.current.value = "";
			rNumACAE.current.value = "";
			rFecNacimiento.current.value = "";
			rDomicilio.current.value = "";
			rCiudad.current.value = "";
			rProvincia.current.value = "";
			rTelefono.current.value = "";
			rObs.current.value = "";
			setValidado(false);
			setClave("");
			setMail("");

			let dojo = props.ListParams.idDojo;
			let asoc = props.ListParams.cfAsoc;
			if (!perfil_admin) asoc = perfil_asoc;
			if (!perfil_admin & !perfil_adm_asoc) dojo = perfil_dojo;
			setIdAsociacion(asoc);
			setIdDojo(dojo);
			setEsAdmin(false);
			setEsAdmDo(false);
			setEsAdmAs(false);
			rNumACAE.current.focus();
		}

		// Establecemos el nombreCompleto en blanco para que cuando cargue un asociado no salga el nombre del anterior asociado
		setNombreCompleto("");
	}, [
		props.show,
		peticion,
		perfil_adm_asoc,
		perfil_admin,
		perfil_asoc,
		perfil_dojo,
		props.ListParams,
	]);

	useEffect(() => {
		// console.log('IdAsociacion',IdAsociacion);
		async function verDojos() {
			//descargo las Asociaciones
			const pet = await peticion(
				"/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc=" + IdAsociacion
			);
			if (pet.estado)
				//algún error
				return;
			setDojos(pet?.res ?? []);
		}
		setErrAsociacion("");
		setErrDojo("");
		if (parseInt(IdAsociacion) === 0) {
			setDojos([]);
			return;
		}
		verDojos();
		// eslint-disable-next-line
	}, [IdAsociacion]);
	// useEffect(() => {
	// 	console.log(Imagen)
	// }, [Imagen]);

	//guardar registro
	async function guardarRegistro() {
		//filtros sin necesidad de peticiones
		const expMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^$/;
		const expDNI = /^([X-Z]\d{7}[A-Z])|(\d{8}[A-Z])$/;
		const expNAcae = /^\d{2}[A-Z]\d{4}$|^$/;
		// const expTelefono = /^\d{9}$/
		const fechaLimite = moment("1900-01-01"); // fecha límite
		let error = false;
		//requeridos
		error |= validErr(
			rNombre.current.value === "",
			setErrNombre,
			loc("El nombre es requerido.")
		);
		error |= validErr(
			rApellido.current.value === "",
			setErrApellido,
			loc("El apellido es requerido.")
		);
		// error|=validErr(rMail.current.value === '',setErrMail,loc('El correo es requerido.'))
		error |= validErr(
			rFecNacimiento.current.value === "",
			setErrFecha,
			loc("La fecha de nacimiento es requerida.")
		);
		error |= validErr(
			rDojo.current.value === "0",
			setErrDojo,
			loc("El dojo es requerido.")
		);
		error |= validErr(
			rAsoc.current.value === "0",
			setErrAsociacion,
			loc("La asociación es requerida.")
		);
		error |= validErr(
			rDNI.current.value !== "" && !expDNI.test(rDNI.current.value),
			setErrDNI,
			loc("Introduce un DNI válido.")
		);
		error |= validErr(
			!expNAcae.test(rNumACAE.current.value),
			setErrNACAE,
			loc("Num. con formato no válido.")
		);
		error |= validErr(
			!expMail.test(rMail.current.value),
			setErrMail,
			loc("Introduce un correo válido.")
		);
		error |= validErr(
			rFecNacimiento.current.value !== "" &&
				(!moment(rFecNacimiento.current.value).isBefore(moment()) ||
					!moment(rFecNacimiento.current.value).isAfter(fechaLimite)),
			setErrFecha,
			loc("La fecha de nacimiento no es válida")
		);
		if (props.show < 0 && !perfil_admin) {
			//insercion sin ser admin
			error |= validErr(
				rAsoc.current.value !== perfil_asoc.toString(),
				setErrAsociacion,
				loc("No puedes insertar en esa asociación.")
			);
			if (!perfil_adm_asoc)
				//adm_dojo
				error |= validErr(
					rDojo.current.value !== perfil_dojo.toString(),
					setErrDojo,
					loc("No puedes insertar en ese dojo.")
				);
		}
		if (props.show > 0 && !perfil_admin && !perfil_adm_asoc) {
			//modificación sin ser admin o adm_asoc
			error |= validErr(
				rAsoc.current.value !== perfil_asoc.toString(),
				setErrAsociacion,
				loc("No puedes guardar en esa asociación.")
			);
			if (!perfil_adm_dojo)
				//sin permisos
				error |= validErr(
					rDojo.current.value !== perfil_dojo.toString(),
					setErrDojo,
					loc("No puedes cambiar de dojo.")
				);
		}

		if (error)
			//algún error
			return;
		// segunda tanda de errores
		//filtros con peticiones
		let pet;
		// peticion que comprueba que no haya un mail repetido
		if (rMail.current.value !== "") {
			pet = await peticion(
				`/asociados/rep_mail?mail=${rMail.current.value}&id=${props.show}`
			);
			if (pet?.estado ?? 1) return;
			if ((pet?.res?.idAso ?? 1) > -1) {
				setErrMail(loc("Ya existe un asociado con ese correo"));
				return;
			}
		}
		// peticion que comprueba que no haya un numAcae repetido
		if (rNumACAE.current.value !== "") {
			pet = await peticion(
				`/asociados/rep_numAcae?numAcae=${rNumACAE.current.value}&id=${props.show}`
			);
			if (pet?.estado ?? 1) return;
			if ((pet?.res?.idAso ?? 1) > -1) {
				setErrNACAE(loc("Ya existe un asociado con ese número"));
				return;
			}
		}
		if (props.show < 0)
			//insercion
			pet = await peticion("/asociados/ins", {
				method: "POST",
				json: {
					dni: rDNI.current.value.toUpperCase(),
					nombre: rNombre.current.value,
					apellido: rApellido.current.value,
					mail: rMail.current.value,
					numAcae: rNumACAE.current.value,
					fechaNacimiento: rFecNacimiento.current.value,
					domicilio: rDomicilio.current.value,
					ciudad: rCiudad.current.value,
					provincia: rProvincia.current.value,
					telefono: rTelefono.current.value,
					obs: rObs.current.value,
					esAdmin: EsAdmin,
					esAdmDo: EsAdmDo,
					esAdmAs: EsAdmAs,
					cfDojo: IdDojo,
					cfAsoc: IdAsociacion,
					avatar: Imagen,
				},
			});
		else if (props.show > 0)
			//modificacion
			pet = await peticion("/asociados/mod", {
				method: "POST",
				json: {
					id: rId.current.value,
					dni: rDNI.current.value.toUpperCase(),
					nombre: rNombre.current.value,
					apellido: rApellido.current.value,
					mail: rMail.current.value,
					numAcae: rNumACAE.current.value,
					fechaNacimiento: rFecNacimiento.current.value,
					domicilio: rDomicilio.current.value,
					ciudad: rCiudad.current.value,
					provincia: rProvincia.current.value,
					telefono: rTelefono.current.value,
					obs: rObs.current.value,
					esAdmin: EsAdmin,
					esAdmDo: EsAdmDo,
					esAdmAs: EsAdmAs,
					cfDojo: IdDojo,
					cfAsoc: IdAsociacion,
					avatar: Imagen,
				},
			});

		let id = pet?.res?.id ?? rId.current.value;
		if (pet.estado)
			//algún error
			return;
		cierra();
		if (acc === "mod")
			//quitamos el "mod" de la url
			navigate(`/${lang}/app/asociados/dojo_${props.ListParams.idDojo}/${id}`);
		else if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev) => ({ ...prev, idReg: 0 }));
		else
			navigate(`/${lang}/app/asociados/dojo_${props.ListParams.idDojo}/${id}`);
	}

	//resetea la psw y lo pone pendiente de validar
	async function resetPSW() {
		//filtros sin necesidad de peticiones
		const pet = await peticion("/asociados/resetPSW", {
			method: "POST",
			json: {
				id: rId.current.value,
			},
		});
		if (pet?.estado ?? 1) return;
		let texto = window.location.origin;
		texto += `/${lang}/app/?mail=${rMail.current.value}&valCode=${
			pet?.res?.clave ?? ""
		}`;
		navigator.clipboard.writeText(texto);
		Swal.fire({
			title: loc("Contraseña reseteada."),
			text: loc(
				"El usuario tendrá que volver a acceder con el enlace que se ha copiado en el portapapeles."
			),
			showConfirmButton: true,
			icon: "success",
		});
		cierra();
	}

	//copia la url de validación al portapapeles
	async function copURL() {
		let texto = window.location.origin;
		texto += `/${lang}/app/?mail=${rMail.current.value}&valCode=${Clave}`;
		navigator.clipboard.writeText(texto);
		Swal.fire({
			title: loc("Enlace copiado al portapapeles."),
			text: loc("Mándaselo al asociado, para que pueda validar su usuario."),
			showConfirmButton: true,
			icon: "success",
		});
	}
	//cierra el modal
	function cierra() {
		setErrDNI("");
		setErrNombre("");
		setErrApellido("");
		setErrMail("");
		setErrNACAE("");
		setErrFecha("");
		setErrDomicilio("");
		setErrCiudad("");
		setErrProvincia("");
		setErrTelefono("");
		setErrObs("");
		setImagen(null);
		setClassAvatar("avCerrado");
		setEsAdmin(false);
		setEsAdmAs(false);
		setEsAdmDo(false);
		props.onHide();
		if (acc === "mod")
			//quitamos el "mod" de la url
			navigate(`/${lang}/app/asociados/dojo_${props.ListParams.idDojo}`);
	}

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<AsociadoIcon className="section-icon asociados" />
							<h3>
								{props.show > 0
									? loc("Modificar asociado")
									: loc("Nuevo asociado")}
							</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt={loc("Cerrar ventana")}
							title="(ESC)"
						/>
					</div>
					<form className="modal-content-scroll form-container">
						<div className="modal-content-outer-wrapper">
							<div className="form-scroll">
								<div className="user-avatar">
									<div className="flex-row-item">
										<NewimgIcon
											className="newimg-icon"
											onClick={() => {
												setClassAvatar(
													ClassAvatar === "avAbierto"
														? "avCerrado"
														: "avAbierto"
												);
											}}
										/>
										<img
											src={
												Imagen !== null
													? Imagen
													: "/avatars/" + (RutAvatar ?? "default.jpg")
											}
											alt={loc("Avatar de usuario")}
										/>
										<div className="user-avatar__name">
											<span>{nombreCompleto}</span>
											<div className="flex-wrapper center">
												<button
													ref={rBotAdmin}
													type="button"
													disabled={!perfil_admin}
													onClick={() => {
														setEsAdmin(!EsAdmin);
													}}
													className={`button tertiary ${
														EsAdmin ? "active" : ""
													}`}>
													{loc("Admin")}
												</button>
												<button
													ref={rBotAdmAs}
													type="button"
													disabled={!perfil_admin & !perfil_adm_asoc}
													onClick={() => {
														setEsAdmAs(!EsAdmAs);
													}}
													className={`button tertiary ${
														EsAdmAs ? "active" : ""
													}`}>
													{loc("Admin Asociación")}
												</button>
												<button
													ref={rBotAdmDo}
													type="button"
													onClick={() => {
														setEsAdmDo(!EsAdmDo);
													}}
													disabled={
														!perfil_admin & !perfil_adm_asoc & !perfil_adm_dojo
													}
													className={`button tertiary ${
														EsAdmDo ? "active" : ""
													}`}>
													{loc("Admin Dojo")}
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className="horizontal-divider"></div>

								<div className="associates-data">
									<div className="column-duo underline relative-container">
										<p>{loc("Número de ACAE")}</p>
										<input
											ref={rNumACAE}
											onChange={() => setErrNACAE("")}
											disabled={props.show > 0}
											placeholder={loc(
												"Número de ACAE (vacío para autogenerar)"
											)}
											maxLength={15}
										/>
										<p className="error">{ErrNACAE}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Nombre")}*</p>
										<input
											ref={rNombre}
											onChange={() => setErrNombre("")}
											placeholder={loc("Nombre del asociado")}
											maxLength={50}
										/>
										<p className="error">{ErrNombre}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Apellidos")}*</p>
										<input
											ref={rApellido}
											onChange={() => setErrApellido("")}
											placeholder={loc("Apellidos del asociado")}
											maxLength={50}
										/>
										<p className="error">{ErrApellido}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Mail")}</p>
										<input
											ref={rMail}
											onChange={() => {
												setMail(rMail.current.value);
												setErrMail("");
											}}
											placeholder={loc("Correo del asociado")}
											maxLength={50}
										/>
										<p className="error">{ErrMail}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Fecha de nacimiento")}*</p>
										<input
											type="date"
											className="inputfecha"
											ref={rFecNacimiento}
											onChange={() => setErrFecha("")}
											maxLength={20}
										/>
										<p className="error">{ErrFecha}</p>
									</div>
									<div className="column-duo overline relative-container">
										<p>{loc("Asociación")}*</p>
										<select
											ref={rAsoc}
											value={IdAsociacion}
											onChange={() => {
												setIdAsociacion(rAsoc.current.value);
											}}>
											<option value={0}>{loc("Seleccionar asociación")}</option>
											{Asociaciones.map((elem) => (
												<option value={elem.idAsoc} key={elem.idAsoc}>
													{elem.nombre} ({elem.zona})
												</option>
											))}
										</select>
										<p className="error">{ErrAsociacion}</p>
									</div>
									<div className="column-duo underline relative-container">
										<p>{loc("Dojo")}*</p>
										<select
											ref={rDojo}
											value={IdDojo}
											onChange={() => {
												setErrDojo("");
												setIdDojo(rDojo.current.value);
											}}>
											<option value={0}>{loc("Seleccionar dojo")}</option>
											{Dojos.map((elem) => (
												<option value={elem.idDojo} key={elem.idDojo}>
													{elem.nombre}
												</option>
											))}
										</select>
										<p className="error">{ErrDojo}</p>
									</div>
									<div className='relative-container'>
										<input ref={rId} type="hidden" />
										<p>{loc("DNI")}</p>
										<input
											ref={rDNI}
											onChange={() => setErrDNI("")}
											placeholder={loc("DNI del asociado")}
											maxLength={9}
										/>
										<p className="error">{ErrDNI}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Teléfono")}</p>
										<input
											ref={rTelefono}
											onChange={() => setErrTelefono("")}
											placeholder={loc("Teléfono del asociado")}
											maxLength={9}
										/>
										<p className="error">{ErrTelefono}</p>
									</div>
									<div className="column-duo relative-container">
										<p>{loc("Domicilio")}</p>
										<input
											ref={rDomicilio}
											onChange={() => setErrDomicilio("")}
											placeholder={loc("Domicilio del asociado")}
											maxLength={150}
										/>
										<p className="error">{ErrDomicilio}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Ciudad")}</p>
										<input
											ref={rCiudad}
											onChange={() => setErrCiudad("")}
											placeholder={loc("Ciudad del asociado")}
											maxLength={50}
										/>
										<p className="error">{ErrCiudad}</p>
									</div>
									<div className='relative-container'>
										<p>{loc("Provincia")}</p>
										<input
											ref={rProvincia}
											onChange={() => setErrProvincia("")}
											placeholder={loc("Provincia del asociado")}
											maxLength={50}
										/>
										<p className="error">{ErrProvincia}</p>
									</div>
									<div className="column-duo overline relative-container">
										<p>{loc("Observaciones")}</p>
										<textarea
											ref={rObs}
											onChange={() => setErrObs("")}
											placeholder={loc("Observaciones del asociado")}
											maxLength={250}
										/>
										<p className="error">{ErrObs}</p>
									</div>
								</div>
							</div>
						</div>
					</form>

					<div className="modal-footer">
						<div className="flex-row-item space-between">
							{(props.show < 0) | (Mail === "") ? null : !Validado &&
							  Clave !== "" ? ( // solo visible si está por validar y tiene clave (tiene permisos)
								<button
									type="button"
									onClick={() => copURL()}
									className="button primary">
									{loc("Enlace validación")}
									<LinkIcon alt="Aplicar" title="Aplicar" />
								</button>
							) : (
								<button
									type="button"
									onClick={() => {
										Swal.fire({
											title: loc(
												"¿Seguro que quieres resetar la contraseña de este usuario?"
											),
											showCancelButton: true,
											confirmButtonText: loc("Sí"),
											icon: "question",
											cancelButtonText: loc("No"),
										}).then((result) => {
											if (result.isConfirmed) resetPSW();
										});
									}}
									className="button primary">
									{loc("Resetear contraseña")}
									<LockIcon alt="Aplicar" title="Aplicar" />
								</button>
							)}
							<div className="flex-wrapper flex-end">
								<button
									title="ALT+G"
									type="button"
									onClick={guardarRegistro}
									className="button primary">
									{loc("Aplicar")}
									<GuardarIcon alt={loc("Aplicar")} title={loc("Aplicar")} />
								</button>
								<button className="button tertiary" onClick={cierra}>
									{loc("Cancelar")}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className={`img-avatar-wrapper ${ClassAvatar}`}>
					<div className="img-avatar-container">
						{/* <CloseIcon onClick={()=>{setClassAvatar('avCerrado');setImagen(null)}} className='modal_close-icon avatar' /> */}
						<Avatar
							className="img-avatar"
							imageHeight={400}
							onClose={() => {
								setClassAvatar("avCerrado");
								setImagen(null);
							}}
							onCrop={(img) => {
								setImagen(img);
							}}
							label={loc("Subir imagen")}
							exportSize={100}
							src={null}
						/>
						{Imagen !== null ? (
							<button
								type="button"
								className="cta"
								onClick={() => {
									setClassAvatar("avCerrado");
								}}>
								Aplicar
							</button>
						) : (
							<button
								type="button"
								className="cta avatar-cerrar"
								onClick={() => {
									setClassAvatar("avCerrado");
								}}>
								<CloseIcon />
							</button>
						)}
					</div>
					<div className="img-avatar-container__bg"></div>
				</div>
			</main>
		);
};

export default AsociadoMod;
