import React, {useContext} from "react";
import "../assets/css/modal.css";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";

//Contexto
import ConexContext from "../context/conex/ConexContext";

const CursoImagenMod = (props) => {
	const { image } = props;

	const {
		loc
	} = useContext(ConexContext); //contexto
	
	//cierra el modal
	function cierra() {
		props.onHide();
	}

	if (props.show === false) return "";
	else
		return (
			<main className="modal-wrapper" onClick={cierra}>
				<div className="modal-curso-img__img backdrop">
					<img className="modal-curso-img__img" src={image} alt=""/>
					<CloseIcon
						onClick={cierra}
						className="modal_close-icon"
						alt={loc("Cerrar ventana")}
						title="(ESC)"
					/>
				</div>
			</main>
		);
};

export default CursoImagenMod;
