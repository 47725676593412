import React from 'react'


const Avatar = (avatar) => {

	return (
		<div className='user-avatar desktop'>
			<div>
				<img src={'/avatars/' + (avatar.avatar ?? 'default.jpg')} alt='Avatar' className='avatar' />
			</div>
		</div>
	)
}

export default Avatar