import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import ConexContext from "../context/conex/ConexContext";
import "../assets/css/acae.css";

//SVG
import { ReactComponent as InicioIcon } from "../assets/img//iconos/acae_home.svg";
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";

//IMG
// import loadingGif from "../assets/img/loading.gif";
import flor from "../assets/img/flor.svg";
import AvatarSimple from "./comun/AvatarSimple";
import MenuToggle from "./comun/menuToggle";
import InscripcionMod from "./InscripcionMod";
import MisCursosMod from "./MisCursosMod";

export const Inicio = ({ setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_id,
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_asoc,
		perfil_dojo,
		Cargando,
	} = useContext(ConexContext); // Contexto
	const { idReg, lang } = useParams();

	const [avatar, setAvatar] = useState("default.jpg");
	const [nombreCompleto, setNombreCompleto] = useState(""); // Estado para almacenar el nombre del asociado
	const [numAcae, setNumAcae] = useState(""); // Estado para almacenar el número de acae
	const [Asociaciones, setAsociaciones] = useState([]); //clase con las asociaciones
	const [Dojos, setDojos] = useState([]); //clase con los dojos
	const [asociacion, setasociacion] = useState(""); //Estado para almacenar el nombre de la asociacion
	const [Cuotas, setCuotas] = useState([]); //cuotas pagadas
	const [DojoCuotas, setDojoCuotas] = useState([]); //Dojo de cuotas pagadas
	const [Cursos_ASI, setCursos_ASI] = useState([]); //cursos asistidos y pagados del asociado
	const [Cursos, setCursos] = useState([]); //cursos del asociado
	const [mostrarTodos, setMostrarTodos] = useState(0); // modal cursos cursados
	// const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	// const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [VerIns, setVerIns] = useState(0); // para ver el modal de Inscribirse
	const [asociadoData, setAsociadoData] = useState(null); // para ver el modal de Inscribirse
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "titulo DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	const colCuotas = [
		"rgb(22 162 147)",
		"rgb(168 0 59)",
		"rgb(224 147 30)",
		"rgb(48 122 180)",
		"rgb(40 158 105)",
		"rgb(183 53 53)",
		"rgb(214 59 113)",
		"rgb(44 184 211)",
		"rgb(48 158 40)",
		"rgb(199 106 0)",
		"rgb(121 18 54)",
		"rgb(106 122 204)",
		"rgb(22 190 77)",
		"rgb(203 63 63)",
		"rgb(168 0 158)",
		"rgb(65 128 199)",
		"rgb(35 208 113)",
		"rgb(228 24 24)",
		"rgb(188 83 132)",
		"rgb(87 64 196)",
	];

	//useEffect
	useEffect(() => {
		const ver = async () => {
			//Nombre asociado
			peticion("/asociados/ver?id=" + perfil_id).then((pet) => {
				if (pet.estado === 0) {
					setNombreCompleto(pet.res?.nombre);
					setAvatar(pet.res?.avatar);
					setasociacion(pet.res?.nombreAsoc);
					setNumAcae(pet.res?.numAcae);
					setAsociadoData(pet.res);
				}
			});
			// descargo las asociaciones
			peticion("/asocs/?ini=0&pag=1000&orden=nombre&filtro=").then((pet) => {
				if (pet.estado === 0) setAsociaciones(pet?.res ?? []);
			});
			//descargo los dojos de la asociación que tenga en el perfil
			peticion(
				"/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc=" + perfil_asoc
			).then((pet) => {
				if (pet.estado === 0) setDojos(pet?.res ?? []);
			});
			//cuotas del asociado
			peticion("/cuotas/listCuotas?idAsoc=" + perfil_id).then((pet) => {
				setDojoCuotas(pet?.Dojo ?? []);
				if (pet.estado === 0) setCuotas(pet?.res ?? []);
			});
			//cursos del asociado
			peticion("/cursos/listCursos?idAsoc=" + perfil_id).then((pet) => {
				if (pet.estado === 0) setCursos_ASI(pet?.res ?? []);
			});
		};
		ver();
		// eslint-disable-next-line
	}, [perfil_id, perfil_asoc, peticion]);

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			return;
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setCursos(pet.res);
		// setNRes(pet.nRes);
		// setNTot(pet.nTot);
	};

	//Para cambiar el perfil de adm de dojo
	const cambPerfil = async (id, tipo) => {
		if (id === "") return;
		await peticion(`/modPerfil?id=${id}&tipo=${tipo}`);
	};

	return (
		<>
			<div className="content-wrapper main-section" id="inicio">
				<header className="backdrop flex-row-item space-between">
					<div className="flex-row-item">
						<InicioIcon className="section-icon" alt="Inicio" />
						<h1>{loc("Inicio")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !VerIns && !mostrarTodos ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>{loc("Cargando...")}</span>
					</div>
				) : (
					<div className="content-wrapper">
						<div className="backdrop onboarding">
							<div className="flex-row-item space-between">
								<div className="flex-row-item no-gap">
									<h2>
										¡{loc("Bienvenido") + ", "}
										<Link to={`/${lang}/app/asociados/dojo_0/${perfil_id}/mod`}>
											{nombreCompleto}
										</Link>
										!
									</h2>
									<h2 className="numacae">{numAcae}</h2>
								</div>
								<AvatarSimple avatar={avatar} />
							</div>
							{!perfil_admin && !perfil_adm_asoc && !perfil_adm_dojo ? (
								<>
									<div className="management">
										<div className="horizontal-divider"></div>
										<ul>
											<li className="flex-row-item gap-small">
												<UsersIcon />
												<div className="dojo_container">{asociacion}</div>
											</li>
											<li className="flex-row-item gap-small">
												<DojoIcon />
												{Dojos.map(
													(elem) =>
														elem.idDojo === asociadoData.cfDojo && (
															<div
																className="dojo_container"
																value={elem.idDojo}
																key={elem.idDojo}>
																{elem.nombre}
															</div>
														)
												)}
											</li>
										</ul>
									</div>
								</>
							) : (
								<>
									<div className="management">
										<div className="active_user-rights">
											{perfil_admin && <h3>{loc("Admin ACAE")}</h3>}
											{perfil_adm_asoc && <h3>{loc("Admin Asociación")}</h3>}
											{perfil_adm_dojo && <h3>{loc("Admin Dojo")}</h3>}
										</div>
										<div className="horizontal-divider"></div>
										<ul>
											{!perfil_admin && !perfil_adm_asoc ? null : (
												<li className="flex-row-item gap-small">
													<UsersIcon />
													<Link to={{ pathname: `/${lang}/app/dojos/asoc_${perfil_asoc}` }}>
														<button className="ver-item">
															<ViewIcon />
														</button>
													</Link>
													<select
														value={perfil_adm_asoc ? perfil_asoc : ""}
														onChange={(el) => {
															cambPerfil(el.target.value, "asoc");
														}}
														disabled={!perfil_admin}>
														{perfil_adm_asoc ? null : (
															<option value="" key="_">
																{loc("No eres administrador de asociación")}
															</option>
														)}
														{Asociaciones.map((elem) => (
															<option value={elem.idAsoc} key={elem.idAsoc}>
																{elem.nombre} ({elem.zona})
															</option>
														))}
													</select>
												</li>
											)}
											{asociacion && !perfil_admin && !perfil_adm_asoc && (
												<li className="flex-row-item gap-small">
													<UsersIcon />
													<Link to={{ pathname: `/${lang}/app/dojos/asoc_${perfil_asoc}` }}>
														<button className="ver-item">
															<ViewIcon />
														</button>
													</Link>
													<div className="dojo_container">{asociacion}</div>
												</li>
											)}
											<li className="flex-row-item gap-small">
												<DojoIcon />
												<Link to={{ pathname: `/${lang}/app/asociados/dojo_${perfil_dojo}` }}>
														<button className="ver-item">
															<ViewIcon />
														</button>
													</Link>
												<select
													onChange={(el) => {
														cambPerfil(el.target.value, "dojo");
													}}
													value={perfil_adm_dojo ? perfil_dojo : ""}
													disabled={!perfil_adm_asoc && !perfil_admin}>
													{perfil_adm_dojo ? null : (
														<option value="" key="_">
															{loc(
																"No eres administrador de dojo para esta asociación"
															)}
														</option>
													)}
													{Dojos.map((elem) => (
														<option value={elem.idDojo} key={elem.idDojo}>
															{elem.nombre}
														</option>
													))}
												</select>
											</li>
										</ul>
									</div>
								</>
							)}
						</div>

						<div className="grid">
							<div className="backdrop paid-fees">
								<div className="content-wrapper">
									<h2>{loc("Cuotas")}</h2>
									<ul className="flex-row-item gap-small">
									{Cuotas.length > 0 ? (
										Cuotas.map((el, i) => (
											<li
												className="flex-row-item gap-small"
												key={el}
												style={{ background: colCuotas[el % 20] }}
												title={DojoCuotas && DojoCuotas[i]?.Dojo ? `Pagado en ${DojoCuotas[i].Dojo}` : "Información no disponible"}>
												<span>{el}</span>
												<img src={flor} alt="" />
											</li>
										))
									) : (<div className="dcontent-wrapper"><p className="proximamente">No hay cuotas pagadas</p></div>)}
									</ul>
								</div>
							</div>
							<div className="backdrop grados-overview">
								<div className="content-wrapper">
									<h2>{loc("Grados")}</h2>
									<p className="proximamente">{loc("Próximamente disponible")}</p>
								</div>
							</div>
							<div className="backdrop cursos-overview">
								<div className="content-wrapper">
									<div className="flex-row-item gap-small">
										<h2>{loc("Mis cursos")}</h2><span className="estado-container">{Cursos_ASI.length}</span>
									</div>
									<ul className="list-view-curso grid">
										{Cursos_ASI.length > 0 ? (
											<>
												{Cursos_ASI.slice(0, 4).map((el) => (
													<li key={el.idcurso} className="cursos-lista">
														<div className="flex-row-item gap-small">
															<AcaeIcon />
															<p className="location">{el?.ciudad}</p>
														</div>
														<p className="date">
															{
																new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																}).format(new Date(el.fechaini)).toUpperCase()
															}
															{" — "}
															{
																new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																	month: "short",
																	year: "numeric",
																}).format(new Date(el.fechafin)).toUpperCase()
															}
														</p>
													</li>
												))}
											</>
										) : (
											<p className="proximamente">{loc("No tienes cursos")}</p>
										)}
									</ul>
									{Cursos_ASI.length > 0 && 
										<button
											className="button ver-mas__lite"
											onClick={() => setMostrarTodos(perfil_id)}>
											{loc("Ver todos")}
										</button>
									}
								</div>
							</div>
						</div>
						<div className="backdrop cursos-disponibles">
							<div className="content-wrapper">
								<h2>{loc("Próximos cursos")}</h2>
								<ul className="quick-view-curso grid">
									{Cursos.length > 0 ? (
										Cursos.map((curso) =>
											new Date(curso.fechaFin) >= new Date() &&
											curso.cfValAdmi ? (
												<li className="quick-view-curso__item" key={curso.idCurso}>
													<div className="flex-column gap-small">
														<h4>{curso.titulo}</h4>
														<div className="flex-row-item gap-small">
															<p className="date">
																{new Intl.DateTimeFormat((lang + "-" + lang.toUpperCase()), {
																	day: "numeric",
																	month: "long",
																})
																	.format(new Date(curso.fechaIni))
																	.toUpperCase()}
															</p>
															{new Date(curso.fechaIni) < new Date() ? (
																<div className="estado-curso">{loc("En curso")}</div>
															) : null}
														</div>
														<div className="flex-row-item gap-small">
															<CursoIcon />
															<p className="location">{curso.ciudad}</p>
														</div>
													</div>
													<button
														className="cta"
														onClick={() => {
															setVerIns(curso.idCurso);
														}}>
														{loc("Apúntate")}
													</button>
												</li>
											) : null
										)
									) : (
										<p className="proximamente">{loc("No hay cursos disponibles")}</p>
									)}
								</ul>
							</div>
						</div>

						<div className="backdrop tribunales-disponibles">
							<div className="content-wrapper">
								<h2>{loc("Tribunales")}</h2>
								<p className="proximamente">{loc("Próximamente disponible")}</p>
							</div>
						</div>

						<InscripcionMod
							show={VerIns}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerIns(0)}
							idAsociado={perfil_id}
						/>
					</div>
				)}
			</div>
			<MisCursosMod show={mostrarTodos} onHide={() => setMostrarTodos(0)} />
		</>
	);
};
