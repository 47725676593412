import React, { useContext } from 'react'

//Contexto
import ConexContext from "../../context/conex/ConexContext";

import Filtrado from './Filtrado'

const Paginacion = ({ nrPag, NRes, NTot, Ini, Num, Filtro, setListParams }) => {
	const { loc } = useContext(ConexContext)//contexto
	const nPgMx = 7// numerom de paginas máximas
	//FUNCIONES
	//Devuelve un array con un elemento por cada pagina que se vaya a mostrar
	const paginas = () => {
		const arr = []
		for (let i = 0; i < NRes; i += Num)
			arr.push({
				num: i,
				vista: false,
				puntos: false
			})
		if (arr.length === 0)
			return ([])
		let n = Ini / Num
		//el primero y el ultimo siempre se ve
		arr[0].vista = true
		arr[arr.length - 1].vista = true
		n -= Math.ceil(nPgMx / 2)
		let tPgMx = nPgMx - 2
		// toqueteamos para que se enseñen más o menos si es necesario
		if (n < 0) {
			n = 0
			tPgMx++
		}
		else if (n + tPgMx + 2 >= arr.length)
			n = arr.length - tPgMx - 1
		else
			n += 2
		for (let i = 0; i < tPgMx; i++) {
			if (n + i < 0) continue
			if (n + i > arr.length - 1) continue
			arr[n + i].vista = true
		}
		//puntos
		if (arr.length > 4) {
			if (!arr[1].vista) arr[1].puntos = true
			if (!arr[arr.length - 2].vista) arr[arr.length - 2].puntos = true
		}
		return (arr)

	}


	return (
		<div className="page-navigation">
			<div className='n-results'>
				<select className='view-input' value={Num} onChange={(e) => setListParams((prev) => ({ ...prev, num: parseInt(e.target.value), ini: 0 }))}>
					<option value={15}>15</option>
					<option value={50}>50</option>
					<option value={100}>100</option>
				</select> {loc('de')} {NTot} {loc('resultados')}
			</div>

			{/* {Filtro === '' ? '' :
				<><button onClick={() => setListParams((prev) => ({ ...prev, filtro: '' }))} >{loc('Quitar filtro')}</button>
				</>
			} */}

			<div aria-label="Page navigation">
				<ul className="pagination">
					<li className="page-item">
						<button
							onClick={() => setListParams((prev) => ({ ...prev, ini: Ini - Num }))}
							className={`page-link antsig ${Ini === 0 && 'pagdes'}`}>{loc('Anterior')}
						</button>
					</li>
					{paginas().map((elem, n) => (
						elem.vista ?
							<li key={n} className="page-item">
								<button
									onClick={() => setListParams((prev) => ({ ...prev, ini: elem.num }))}
									className={`page-link ${elem.num === Ini && 'pagactive'}`}>{n + 1}
								</button>
							</li>
							: elem.puntos &&
							<li key={n} className="page-item">...</li>
					))}
					<li className="page-item">
						<button
							onClick={() => { setListParams((prev) => ({ ...prev, ini: Ini + Num })) }}
							className={`page-link antsig ${Ini + Num >= NRes && 'pagdes'}`}
							>
							{loc('Siguiente')}
						</button>
					</li>
				</ul>
			</div>
			<Filtrado
				setListParams={setListParams}
				Filtro={Filtro}
			/>
		</div>
	)
}

export default Paginacion