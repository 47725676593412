import React, { useContext } from 'react'
import {
	Link,
	useParams
} from "react-router-dom";

//Contexto
import ConexContext from "../../context/conex/ConexContext";

import cerrarsesion from "../../assets/img/iconos/acae_logout.svg"

const Avatar = () => {
	const { lang } = useParams();
	const {
		loc,
		setToken,
		perfil_avatar,
		perfil_id
	} = useContext(ConexContext)//contexto

	return (
		<div className='active_user-avatar'>
			<Link to={`/${lang}/app/asociados/dojo_0/${perfil_id}/mod`} className='desktop'>
				<img src={'/avatars/' + (perfil_avatar ?? 'default.jpg')} alt='Avatar' className='avatar' />
			</Link>
			<button onClick={() => setToken('')} className="logout">
				<img src={cerrarsesion} alt={loc('Cerrar sesión')} />
				{loc('Cerrar sesión')}
			</button>
		</div>
	)
}

export default Avatar