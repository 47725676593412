import React, { useState, useContext, Fragment } from "react";
import { Modal } from "react-bootstrap";

import ConexContext from '../context/conex/ConexContext';

// import "../assets/css/bootstrap.css"

// import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/download.svg";
import { ReactComponent as PdfIcon } from "../assets/img/pdf.svg";
// import { ReactComponent as CsvIcon } from "../assets/img/csv.svg";

import { CSVLink } from "react-csv";
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;



export const ModalExportar = ({ show, onHide, json }) => {
	const MaxFilasExcel = require('../config/config.json').MaxFilasExcel;

	const { peticion } = useContext(ConexContext)//contexto

	const [data, setData] = useState([]); // datos a exportar
	const [dataCSV, setDataCSV] = useState([]); // datos a exportar
	const [headerCSV, setheaderCSV] = useState([]); // datos a exportar

	const [estado, setEstado] = useState(0);// para el estado de la descarga
	const [error, setError] = useState("");// para el mensaje de error
	const [nfilas, setnfilas] = useState(0);// para el numero de filas

	// const campos = [{
	// 	"NRBE": { "nombre": "NRBE", "tipo": "campo", "titulo": "NRBE" },
	// 	"Entidad": { "nombre": "Entidad", "tipo": "campo", "titulo": "Entidad" },
	// 	"Email": { "nombre": "Email", "tipo": "campo", "titulo": "Email" },
	// 	"Nombre": { "nombre": "Nombre", "tipo": "campo", "titulo": "Nombre" },
	// 	"Tipo de Documento": { "nombre": "Tipo de Documento", "tipo": "campo", "titulo": "Tipo de Documento" },
	// 	"Documento": { "nombre": "Documento", "tipo": "campo", "titulo": "Documento" },
	// 	"Sexo": { "nombre": "Sexo", "tipo": "campo", "titulo": "Sexo" },
	// 	"Fecha de Nacimiento": { "nombre": "Fecha de Nacimiento", "tipo": "campo", "titulo": "Fecha de Nacimiento" },
	// 	"Teléfono": { "nombre": "Teléfono", "tipo": "campo", "titulo": "Teléfono" },
	// 	"Código postal": { "nombre": "Código postal", "tipo": "campo", "titulo": "Código postal" },
	// 	"Provincia": { "nombre": "Provincia", "tipo": "campo", "titulo": "Provincia" },
	// 	"Estado": { "nombre": "Estado", "tipo": "campo", "titulo": "Estado" },
	// 	"Fecha de Estado": { "nombre": "Fecha de Estado", "tipo": "campo", "titulo": "Fecha de Estado" },
	// 	"Hora Estado": { "nombre": "Hora Estado", "tipo": "campo", "titulo": "Hora Estado" },
	// 	"Fecha de Prerregistro": { "nombre": "Fecha de Prerregistro", "tipo": "campo", "titulo": "Fecha de Prerregistro" },
	// 	"Hora Prerregistro": { "nombre": "Hora Prerregistro", "tipo": "campo", "titulo": "Hora Prerregistro" },
	// 	"Canal Prerregistro": { "nombre": "Canal Prerregistro", "tipo": "campo", "titulo": "Canal Prerregistro" },
	// 	"Canal": { "nombre": "Canal", "tipo": "campo", "titulo": "Canal" },
	// 	"Email valido": { "nombre": "Email valido", "tipo": "campo", "titulo": "Email valido" },
	// 	"ID de suscriptor": { "nombre": "ID de suscriptor", "tipo": "campo", "titulo": "ID de suscriptor" },
	// 	"Publicidad": { "nombre": "Publicidad", "tipo": "campo", "titulo": "Publicidad" },
	// 	"Oficina": { "nombre": "Oficina", "tipo": "campo", "titulo": "Oficina" },
	// 	"Empleado": { "nombre": "Empleado", "tipo": "campo", "titulo": "Empleado" },
	// 	"Tarjetero": { "nombre": "Tarjetero", "tipo": "campo", "titulo": "Tarjetero" },
	// 	"Privilegios": { "nombre": "Privilegios", "tipo": "campo", "titulo": "Privilegios" },
	// 	"Cliente entidad financiera": { "nombre": "Cliente entidad financiera", "tipo": "campo", "titulo": "Cliente entidad financiera" },
	// 	"Marca de tarjeta emitida": { "nombre": "Marca de tarjeta emitida", "tipo": "campo", "titulo": "Marca de tarjeta emitida" },
	// 	"Cliente empleado de la entidad": { "nombre": "Cliente empleado de la entidad", "tipo": "campo", "titulo": "Cliente empleado de la entidad" },
	// 	"Cliente cumplidor": { "nombre": "Cliente cumplidor", "tipo": "campo", "titulo": "Cliente cumplidor" },
	// 	"Promocion": { "nombre": "Promocion", "tipo": "campo", "titulo": "Promocion" },
	// 	"Case": { "nombre": "Case", "tipo": "campo", "titulo": "Case" }
	// }]//Distintos campos que tienen que aparecer en el Excel

	//Funcion de distintos campos que tienen que aparecer en el Excel
	const getCampos = (campos, nombres, agregaciones) => {
		// Inicializar el array de campos con un array vacío en la primera posición
		let campos_aux = [{}];
		let campos_objeto = {};
		if (agregaciones.length>0){
			let PrimerObjeto = {
				nombre: "Totales",
				tipo: "campo",
				titulo: "ntotal"
			};
			// Agregar el nuevo objeto al objeto de agregaciones
			campos_objeto['ntotal'] = PrimerObjeto;
			for (let index = 0; index < agregaciones.length; index++) {
				// Asignar al nuevo objeto la estructura deseada
				let nuevoObjeto = {
					nombre: nombres[index] ? nombres[index] : agregaciones[index],
					tipo: "campo",
					titulo: agregaciones[index]
				};
				// Agregar el nuevo objeto al objeto de agregaciones
				campos_objeto[agregaciones[index]] = nuevoObjeto;
			}
		}
		for (let index = 0; index < campos.length; index++) {
			// Asignar al nuevo objeto la estructura deseada
			let nuevoObjeto = {
				nombre: nombres[index] ? nombres[index] : campos[index],
				tipo: "campo",
				titulo: campos[index]
			};
			// Agregar el nuevo objeto al objeto de campos
			campos_objeto[campos[index]] = nuevoObjeto;
		}
		// Colocar el objeto de campos en el array
		campos_aux[0] = campos_objeto;
		return campos_aux
	}

	//Funcion de exportacion y creacion del Excel
	const handleExport = async () => {
		setEstado(1);
		if (json.vista === "") return; //no hay vista seleccionada
		const pet = await peticion(`/listado/custom?excel=${true}`, { method: 'POST', json: json })
		if (pet.estado) //algún error
			return;
		const nFilas = pet.res.nRes;
		setnfilas(nFilas)
		let resExport = [];
		resExport = pet.res.res
		let campos = getCampos(json.campos, pet.res.nombres, json.agregaciones)

		if (resExport.length >= MaxFilasExcel) {
			let headersCSVAux = [];
			let nombresHeaders = [];
			Object.keys(campos[0]).map((ele) => {
				headersCSVAux.push({
					label: campos[0][ele].nombre,
					key: campos[0][ele].titulo,
				})
				nombresHeaders.push(campos[0][ele].titulo)

				return null;
			})
			setheaderCSV(headersCSVAux)
			let datosCSV_AUX = [];
			resExport.forEach((fila) => {
				let filaCSV = {};
				nombresHeaders.forEach((columna) => {
					filaCSV[columna] = fila[columna];
				});
				datosCSV_AUX.push(filaCSV);
			});
			setDataCSV(datosCSV_AUX);

		} else {
			let headers = [];
			Object.keys(campos[0]).map((ele, i) => (
				headers[i] = {
					title: campos[0][ele].nombre, width: { wpx: 200 }, style: { fill: { patternType: "solid", fgColor: { rgb: "A9A9A9" } }, font: { bold: true, sz: "13" } }
				}
			))
			let tabla = [];
			resExport.forEach((ele) => {
				let fila = [];
				let dato = {
					style: { font: { sz: "11" } }
				}
				Object.keys(campos[0]).forEach((id) => {
					if (id === "Nombre") {
						let nombreApellidos = ele[campos[0][id].titulo] === null ? ("") : (ele[campos[0][id].titulo]) + " " + (ele["Apellidos"] === null ? ("") : (ele["Apellidos"]));
						fila.push({ ...dato, value: nombreApellidos })
					} else
						fila.push({ ...dato, value: ele[campos[0][id].titulo] === null ? ("") : (ele[campos[0][id].titulo]) })
				})
				tabla.push(fila);
			});
			setData(...data, [{
				columns: headers,
				data: tabla,
			}])
		}
		setEstado(2);
	};

	//Funcion para la cancelacion del Excel
	const handleCancel = () => {
		setEstado(0);
		setData([]);
		setError("");
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide} animation={false} centered className="export-modal">
			<Modal.Body>
				<div className='flex-wrapper'>
					<PdfIcon className='section-icon' />
					<h3>Exportar resultados</h3>
				</div>
				<div className="modal-body-content">
					{estado === 0 ? (
						<Fragment>
							<span className="modal-subtitle underline">
								Exporta los resultados de tu consulta actual como archivo <strong>{nfilas >= MaxFilasExcel ? (".csv") : (".xlsx")}</strong>
							</span>
						</Fragment>
					) : estado === 1 ? (
						<div className="loader-container">
							<h5>Generando archivo...</h5>
						</div>
					) : (
						<div className="modal-body-content">
							<span className="modal-subtitle underline">
								Descarga la consulta actual como archivo <strong>{nfilas >= MaxFilasExcel ? (".csv") : (".xlsx")}</strong>
							</span>
							<div className="flex-wrapper flex-end">
								{nfilas >= MaxFilasExcel ? (
									<CSVLink
										separator={";"}
										data={dataCSV}
										filename={"Documento.csv"}
										target="_blank"
										headers={headerCSV}>
										<div className="flex-wrapper flex-end">
											<button
												className="button primary"
												alt="Descargar Excel"
												onClick={() => { }}>
												Descargar .csv
												<DownloadIcon alt="Icono PDF" className="download-icon icon" />
											</button>
										</div>
									</CSVLink>
								) : (
									<ExcelFile
										filename="Documento" element={
											<div className="flex-wrapper flex-end">
												<button
													className="button primary"
													alt="Descargar Excel"
													onClick={() => { }}
												>
													Descargar .xlsx
													<DownloadIcon alt="Icono PDF" className="download-icon icon" />
												</button>
											</div>
										}
									>
										<ExcelSheet dataSet={data} name="Documento" />
									</ExcelFile>
								)}

								<button
									onClick={() => {
										handleCancel();
									}}
									className="button tertiary"
								>
									Cancelar
								</button>
							</div>
						</div>
					)}
				</div>
				{error &&
					<div className="subtitular_modal_error">
						{error}
					</div>
				}
				{estado === 0 && (
					<div className="flex-wrapper flex-end">
						<button
							onClick={() => {
								handleExport();
							}}
							className="button primary"
						>
							Generar
						</button>
						<button
							onClick={() => {
								handleCancel();
							}}
							className="button tertiary"
						>
							Cancelar
						</button>
					</div>
				)}

			</Modal.Body>
		</Modal>
	);
};
