//React
import React from 'react';
import ReactDOM from 'react-dom';

//CSS
import './assets/css/reset.css';
import './assets/css/bootstrap.css';
import './assets/fonts/fonts.css';
import './assets/css/acae.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';


import App from './App';

//Contexto
import ConexState from './context/conex/ConexState'

//const root = ReactDOM.createRoot(document.getElementById('root'));

ReactDOM.render(
  <React.StrictMode>
    <ConexState>
      <App />
    </ConexState>
  </React.StrictMode>,
  document.getElementById('root')
);


