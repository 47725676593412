import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import "../assets/css/landingpage.css";

import logo from "../assets/img/logo.jpg";
import kanji from "../assets/img/kanji-aikido.png";
import amazon from "../assets/img/amazon-icon.png";

import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as MenuIcon } from "../assets/img/iconos/acae_menu.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";

// Publicaciones
import publicacion1 from "../assets/img/publicacion1.jpg";
import publicacion2 from "../assets/img/publicacion2.jpg";
import publicacion3 from "../assets/img/publicacion3.jpg";

import espanol from "../assets/img/espanol.png";
import ingles from "../assets/img/uk.png";
import frances from "../assets/img/frances.png";

import ConexContext from "../context/conex/ConexContext";

const LandingPage = () => {
	const { peticion, setLocale, token, loc } = useContext(ConexContext);
	const { idReg, lang } = useParams();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		nombre: "",
		email: "",
		mensaje: "",
	});
	const [message, setMessage] = useState("");

	const sectionsRef = useRef([]);
	const navLinksRef = useRef([]);

	const selLang = useRef();
	const [OpLang, setOpLang] = useState(false);
	const [MenuOpen, setMenuOpen] = useState(false);

	const [currentIndex, setCurrentIndex] = useState(0);
	const [Cursos, setCursos] = useState([]);
	const [activeMenu, setActiveMenu] = useState("");
	const [isScrolling, setIsScrolling] = useState(false);
	// eslint-disable-next-line
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "titulo DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	const [errors, setErrors] = useState({}); // Estado para los errores

	// PARA FORMULARIO FUTURO
	// const handleSubmit = (e) => {
	// 	e.preventDefault();

	// 	const newErrors = {}; // Objeto para registrar los errores
	// 	if (!formData.nombre) newErrors.nombre = loc("El nombre es obligatorio.");
	// 	if (!formData.email) newErrors.email = loc("El email es obligatorio.");
	// 	if (!formData.mensaje) newErrors.mensaje = loc("El mensaje es obligatorio.");

	// 	setErrors(newErrors); // Actualiza el estado con los errores

	// 	if (Object.keys(newErrors).length === 0) {
	// 		console.log("Datos enviados:", formData);
	// 	}
	// };

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			return;
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/public?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);

		if (pet?.estado ?? false) return null;

		const filteredCursos = pet.res.filter(
			(curso) => new Date(curso.fechaFin) >= new Date() && curso.cfValAdmi
		);

		setCursos(filteredCursos);
	};

	const visibleCursos = (() => {
		if (currentIndex === 0) {
			// Primer curso: actual + dos siguientes
			return Cursos.slice(0, 3);
		} else if (currentIndex === Cursos.length - 1) {
			// Último curso: dos anteriores + actual
			return Cursos.slice(Math.max(0, Cursos.length - 3), Cursos.length);
		} else {
			// Caso intermedio: anterior, actual, siguiente
			return Cursos.slice(currentIndex - 1, currentIndex + 2);
		}
	})();

	const handleNavClick = (target) => {
		setActiveMenu(target);
		setIsScrolling(true);

		setTimeout(() => setIsScrolling(false), 300);
	};

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (isScrolling) return;

				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const navLink = navLinksRef.current.find(
							(link) => link?.dataset.target === entry.target.id
						);
						if (navLink) setActiveMenu(navLink.dataset.target);
					}
				});
			},
			{
				root: null,
				rootMargin: "0px 0px -50% 0px",
				threshold: 0.1,
			}
		);

		sectionsRef.current = sectionsRef.current.filter(
			(el) => el instanceof Element
		);
		sectionsRef.current.forEach((section) => observer.observe(section));

		return () => observer.disconnect();
	}, [isScrolling]);
	
	const handleClickOutside = (event) => {
		if (selLang.current && !selLang.current.contains(event.target)) {
			setOpLang(false);
		}
		if (MenuOpen && !event.target.closest('.nav-menu') && !event.target.closest('.menu-toggle-landing')) {
			setMenuOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	// eslint-disable-next-line
	}, [MenuOpen]);

	useEffect(() => {
		setLocale(lang);
	}, [lang, setLocale]);

	return (
		<div id="landing-wrapper">
			<header id="landing-header">
				<nav>
					<div className="nav-logo">
						<img className="logo-img" src={logo} alt="" />
						<div className="flex-column no-gap mobile-hidden">
							<span className="text-bold">ACAE</span>
							<span className="text-italic">
								{loc("Asociación Cultural Aikikai de España")}
							</span>
						</div>
					</div>
					<ul className={`nav-menu ${MenuOpen ? "mostrar" : "ocultar"}`}>
						<li className="nav-li">
							<a
								className={`nav-link ${activeMenu === "eventos" ? "active" : ""}`}
								href="#eventos"
								data-target="eventos"
								onClick={() => handleNavClick("eventos")}
								ref={(el) => {
									if (el && !navLinksRef.current.includes(el))
										navLinksRef.current.push(el);
								}}>
								{loc("Eventos")}
							</a>
						</li>
						<li className="nav-li">
							<a
								className={`nav-link ${activeMenu === "publicaciones" ? "active" : ""}`}
								href="#publicaciones"
								data-target="publicaciones"
								onClick={() => handleNavClick("publicaciones")}
								ref={(el) => {
									if (el && !navLinksRef.current.includes(el))
										navLinksRef.current.push(el);
								}}>
								{loc("Publicaciones")}
							</a>
						</li>
						<li className="nav-li">
							<a
								className={`nav-link ${activeMenu === "dojo-online" ? "active" : ""}`}
								href="#dojo-online"
								data-target="dojo-online"
								onClick={() => handleNavClick("dojo-online")}
								ref={(el) => {
									if (el && !navLinksRef.current.includes(el))
										navLinksRef.current.push(el);
								}}>
								{loc("Dojo online")}
							</a>
						</li>
						<li className="nav-li">
							<a
								className={`nav-link ${activeMenu === "nuestra-historia" ? "active" : ""}`}
								href="#nuestra-historia"
								data-target="nuestra-historia"
								onClick={() => handleNavClick("nuestra-historia")}
								ref={(el) => {
									if (el && !navLinksRef.current.includes(el))
										navLinksRef.current.push(el);
								}}>
								{loc("Nuestra historia")}
							</a>
						</li>
						<li className="nav-li">
							<a
								className={`nav-link ${activeMenu === "contacto" ? "active" : ""}`}
								href="#contacto"
								data-target="contacto"
								onClick={() => handleNavClick("contacto")}
								ref={(el) => {
									if (el && !navLinksRef.current.includes(el))
										navLinksRef.current.push(el);
								}}>
								{loc("Contacto")}
							</a>
						</li>
						<li className="desktop-hidden">
							<div
								className={`selectidiomas loginidiomas ${
									OpLang ? "abierto" : "cerrado"
								}`}
								onClick={() => setOpLang(!OpLang)}
								ref={selLang}
								id="button">
								<h3>
									<span>{lang.toUpperCase()}</span>
									{lang === "es" ? (
										<img src={espanol} alt="" />
									) : lang === "en" ? (
										<img src={ingles} alt="" />
									) : lang === "fr" ? (
										<img src={frances} alt="" />
									) : null}
								</h3>
								<input id="toggle" type="checkbox" />
								<div id="two">
									<ul>
										<li className={lang === "es" ? "selec" : ""}>
											<button onClick={() => navigate("/es")}>
												<span>ES</span>
												<img src={espanol} alt="" />
											</button>
										</li>
										<li className={lang === "en" ? "selec" : ""}>
											<button onClick={() => navigate("/en")}>
												<span>EN</span>
												<img src={ingles} alt="" />
											</button>
										</li>
										<li className={lang === "fr" ? "selec" : ""}>
											<button onClick={() => navigate("/fr")}>
												<span>FR</span>
												<img src={frances} alt="" />
											</button>
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
					<div className="flex-row-item">
						<div className="nav-login">
							<Link to={`/${lang}/app${token ? "/inicio" : ""}`}>
								<button className="button cta">{loc("Acceder")}</button>
							</Link>
						</div>
						<div
							className={`selectidiomas mobile-hidden loginidiomas ${
								OpLang ? "abierto" : "cerrado"
							}`}
							onClick={() => setOpLang(!OpLang)}
							ref={selLang}
							id="button">
							<h3>
								<span>{lang.toUpperCase()}</span>
								{lang === "es" ? (
									<img src={espanol} alt="" />
								) : lang === "en" ? (
									<img src={ingles} alt="" />
								) : lang === "fr" ? (
									<img src={frances} alt="" />
								) : null}
							</h3>
							<input id="toggle" type="checkbox" />
							<div id="two">
								<ul>
									<li className={lang === "es" ? "selec" : ""}>
										<button onClick={() => navigate("/es")}>
											<span>ES</span>
											<img src={espanol} alt="" />
										</button>
									</li>
									<li className={lang === "en" ? "selec" : ""}>
										<button onClick={() => navigate("/en")}>
											<span>EN</span>
											<img src={ingles} alt="" />
										</button>
									</li>
									<li className={lang === "fr" ? "selec" : ""}>
										<button onClick={() => navigate("/fr")}>
											<span>FR</span>
											<img src={frances} alt="" />
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="menu-toggle-landing">
							{MenuOpen ? (
								<CloseIcon
									className="menu-toggle-landing__icon"
									onClick={() => {
										MenuOpen ? setMenuOpen(false) : setMenuOpen(true);
									}}
								/>
							) : (
								<MenuIcon
									className="menu-toggle-landing__icon"
									onClick={() => {
										MenuOpen ? setMenuOpen(false) : setMenuOpen(true);
									}}
								/>
							)}
						</div>
					</div>
				</nav>
			</header>
			<main id="landing-main">
				<section id="hero-section">
					<div className="hero-content__kanji">
						<img src={kanji} alt="" />
					</div>
					<div className="hero-content__text text-italic">
						<p>
							{loc("Estando yo entre cielo y tierra")}
							<br />
							{loc("Al establecer kimusubi con ellos")}
							<br />
							{loc("El estado de mi corazón")}
							<br />
							{loc("Es como seguir el camino del eco")}
						</p>
						<span className="text-bold">—</span>
						<span className="text-bold">Morihei Ueshiba</span>
					</div>
				</section>
				<section id="eventos" ref={(el) => sectionsRef.current.push(el)}>
					<div className="section-content">
						<h2>{loc("Eventos")}</h2>
						{Cursos.length > 0 ? (
							<div className="eventos-slider-wrapper">
								<div className="eventos-slider">
									<ul className="eventos-card-wrapper">
										{visibleCursos.map((curso) => (
											<li
												className={`evento ${
													Cursos[currentIndex]?.idCurso === curso.idCurso
														? "selected"
														: ""
												}`}
												onClick={() => setCurrentIndex(Cursos.indexOf(curso))}
												key={curso.idCurso}>
												<div className="imagen-evento">
													{curso.imagen ? (
														<img
															className="imagen-evento__item"
															src={`/curso/${
																curso.imagen
															}`}
															alt={curso.titulo}
															title={curso.titulo}
														/>
													) : (
														<div className="no-image-container">
															<img
																className="imagen-evento__item"
																src={`/curso/template-cursos.png`}
																alt={curso.titulo}
																title={curso.titulo}
															/>
															<div className="no-image-content">
																<p className="titulo-curso">{curso.titulo}</p>
																<p className="ciudad-curso">{curso.ciudad}</p>
																<p className="fecha-curso">
																	{new Intl.DateTimeFormat(
																		lang + "-" + lang.toUpperCase(),
																		{
																			day: "numeric",
																			month: "short",
																		}
																	)
																		.format(new Date(curso.fechaIni))
																		.toUpperCase()}{" "}
																	—{" "}
																	{new Intl.DateTimeFormat(
																		lang + "-" + lang.toUpperCase(),
																		{
																			day: "numeric",
																			month: "short",
																		}
																	)
																		.format(new Date(curso.fechaFin))
																		.toUpperCase()}
																</p>
																<div className="acae-logo">
																	<img src={logo} alt="ACAE" />
																</div>
															</div>
														</div>
													)}
												</div>
												<div className="flex-column space-between evento-content">
													<div className="flex-column gap-small evento-text">
														<h3>{curso.titulo}</h3>
														<div className="flex-row-item gap-small">
															<p className="date">
																{new Intl.DateTimeFormat(
																	lang + "-" + lang.toUpperCase(),
																	{
																		day: "numeric",
																		month: "short",
																	}
																)
																	.format(new Date(curso.fechaIni))
																	.toUpperCase()}
																{" — "}
																{new Intl.DateTimeFormat(
																	lang + "-" + lang.toUpperCase(),
																	{
																		day: "numeric",
																		month: "short",
																	}
																)
																	.format(new Date(curso.fechaFin))
																	.toUpperCase()}
															</p>
														</div>
														<div className="flex-row-item no-gap ubicacion">
															<CursoIcon />
															<p className="location">{curso.ciudad}</p>
														</div>
													</div>
												</div>
											</li>
										))}
									</ul>
									{/* <button onClick={handlePrev} disabled={currentIndex === 0}>
										{"<"}
									</button>
									<button
										onClick={handleNext}
										disabled={currentIndex === Cursos.length - 1}>
										{">"}
									</button> */}
								</div>
								<div className="horizontal-divider"></div>
								<div className="eventos-showcase">
									<div className="eventos-showcase-img">
										{Cursos[currentIndex]?.imagen ? (
											<img
												className="eventos-showcase-img__item"
												src={`/curso/${
													Cursos[currentIndex]?.imagen
												}`}
												alt={Cursos[currentIndex]?.titulo}
												title={Cursos[currentIndex]?.titulo}
											/>
										) : (
											<div className="no-image-container">
												<img
													className="eventos-showcase-img__item"
													src={`/curso/template-cursos.png`}
													alt={Cursos[currentIndex]?.titulo}
													title={Cursos[currentIndex]?.titulo}
												/>
												<div className="no-image-content">
													<p className="titulo-curso">
														{Cursos[currentIndex]?.titulo}
													</p>
													<p className="ciudad-curso">
														{Cursos[currentIndex]?.ciudad}
													</p>
													<p className="fecha-curso">
														{new Intl.DateTimeFormat(
															lang + "-" + lang.toUpperCase(),
															{
																day: "numeric",
																month: "short",
															}
														)
															.format(new Date(Cursos[currentIndex]?.fechaIni))
															.toUpperCase()}{" "}
														—{" "}
														{new Intl.DateTimeFormat(
															lang + "-" + lang.toUpperCase(),
															{
																day: "numeric",
																month: "short",
															}
														)
															.format(new Date(Cursos[currentIndex]?.fechaFin))
															.toUpperCase()}
													</p>
													<div className="acae-logo">
														<img src={logo} alt="ACAE" />
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="eventos-showcase-content-wrapper">
										<div className="flex-column gap-small eventos-showcase-content">
											<div>
												<h3>{Cursos[currentIndex]?.titulo}</h3>
												<div className="flex-row-item gap-small">
													<p className="date">
														{new Intl.DateTimeFormat(
															lang + "-" + lang.toUpperCase(),
															{
																day: "numeric",
																month: "short",
															}
														)
															.format(new Date(Cursos[currentIndex]?.fechaIni))
															.toUpperCase()}
														{" — "}
														{new Intl.DateTimeFormat(
															lang + "-" + lang.toUpperCase(),
															{
																day: "numeric",
																month: "short",
															}
														)
															.format(new Date(Cursos[currentIndex]?.fechaFin))
															.toUpperCase()}
													</p>
												</div>
											</div>
											<div className="flex-row-item no-gap ubicacion">
												<CursoIcon />
												<p className="location">
													{Cursos[currentIndex]?.ciudad}
												</p>
											</div>
											<p className="eventos-desc">
												{Cursos[currentIndex]?.obs}
											</p>
										</div>
									</div>
								</div>
							</div>
						) : (
							<p className="proximamente">No hay cursos disponibles</p>
						)}
					</div>
				</section>
				<section id="publicaciones" ref={(el) => sectionsRef.current.push(el)}>
					<div className="section-content">
						<h2>{loc("Publicaciones")}</h2>
						<div className="grid publicacion-grid">
							<div className="publicacion-container">
								<div className="publicacion-img">
									<img src={publicacion1} alt="" title="" />
								</div>
								<div className="publicacion-desc">
									<span className="text-bold">
										La plenitud del vacío (1999)
									</span>
									<span className="text-italic">Yasunari Kitaura</span>
									<a
										className="amazon-link"
										href="https://www.amazon.es/dp/8412287347"
										target="_blank"
										follow="nofollow"
										rel="noreferrer">
										<img src={amazon} alt="" />
									</a>
								</div>
							</div>
							<div className="publicacion-container">
								<div className="publicacion-img">
									<img src={publicacion2} alt="" title="" />
								</div>
								<div className="publicacion-desc">
									<span className="text-bold">
										Aikido — Método de movimientos fluidos (2021)
									</span>
									<span className="text-italic">Shu Kitaura</span>
									<a
										className="amazon-link"
										href="https://www.amazon.es/dp/841228738X"
										target="_blank"
										follow="nofollow"
										rel="noreferrer">
										<img src={amazon} alt="" />
									</a>
								</div>
							</div>
							<div className="publicacion-container">
								<div className="publicacion-img">
									<img src={publicacion3} alt="" title="" />
								</div>
								<div className="publicacion-desc">
									<span className="text-bold">Escritura del Aikidō (2021)</span>
									<span className="text-italic">Shu Kitaura</span>
									<a
										className="amazon-link"
										href="https://www.amazon.es/dp/8412287355/"
										target="_blank"
										follow="nofollow"
										rel="noreferrer">
										<img src={amazon} alt="" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="dojo-online" ref={(el) => sectionsRef.current.push(el)}>
					<div className="section-content">
						<h2>{loc("Dojo online")}</h2>
						<div
							className="section-text"
							dangerouslySetInnerHTML={{
								__html: loc("DOJO_ONLINE_CONTENIDO"),
							}}></div>
						<a href="https://ametsuchi-dojo.com">
							<button className="button cta">
								{loc("Visitar Dojo Online")}
							</button>
						</a>
					</div>
				</section>
				<section
					id="nuestra-historia"
					ref={(el) => sectionsRef.current.push(el)}>
					<div className="section-content nuestra-historia">
						<h2>{loc("Nuestra historia")}</h2>
						<div
							className="section-text"
							dangerouslySetInnerHTML={{
								__html: loc("NUESTRA_HISTORIA_CONTENIDO"),
							}}></div>
					</div>
				</section>
				<section id="contacto" ref={(el) => sectionsRef.current.push(el)}>
					<div className="section-content">
						<h2>{loc("Contacto")}</h2>
						<div className="flex-row-item contacto">
							<div
								className="section-text"
								dangerouslySetInnerHTML={{
									__html: loc("CONTACTO_CONTENIDO"),
								}}></div>
							<form
								className="flex-column gap-medium"
								onSubmit={(e) => {
									e.preventDefault();

									// Validar campos
									const newErrors = {};
									if (!formData.nombre)
										newErrors.nombre = loc("El nombre es obligatorio.");
									if (!formData.email)
										newErrors.email = loc("El email es obligatorio.");
									if (!formData.mensaje)
										newErrors.mensaje = loc("El mensaje es obligatorio.");
									setErrors(newErrors);

									// Si hay errores, no continuar
									if (Object.keys(newErrors).length > 0) return;

									// Limpiar formulario y mostrar mensaje de éxito
									setFormData({ nombre: "", email: "", mensaje: "" });
									setMessage(loc("Mensaje enviado correctamente."));

									// Ocultar el mensaje después de 3 segundos
									setTimeout(() => setMessage(""), 3000);
								}}>
								<div className="flex-column no-gap">
									<label htmlFor="nombre">{loc("Nombre")}</label>
									<input
										id="nombre"
										type="text"
										value={formData.nombre}
										onChange={(e) =>
											setFormData({ ...formData, nombre: e.target.value })
										}
									/>
									{errors.nombre && <p className="error">{errors.nombre}</p>}
								</div>
								<div className="flex-column no-gap">
									<label htmlFor="email">{loc("Email")}</label>
									<input
										id="email"
										type="email"
										value={formData.email}
										onChange={(e) =>
											setFormData({ ...formData, email: e.target.value })
										}
									/>
									{errors.email && <p className="error">{errors.email}</p>}
								</div>
								<div className="flex-column no-gap">
									<label htmlFor="mensaje">{loc("Mensaje")}</label>
									<textarea
										id="mensaje"
										value={formData.mensaje}
										onChange={(e) =>
											setFormData({ ...formData, mensaje: e.target.value })
										}
									/>
									{errors.mensaje && <p className="error">{errors.mensaje}</p>}
								</div>
								<div className="flex-row-item gap-small">
									<button className="button cta" type="submit">
										{loc("Enviar")}
									</button>
									{message && <p className="success-message">{message}</p>}
								</div>
							</form>
						</div>
						<iframe
							className="mapa-ametsuchi"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.254892994459!2d-3.708558622726388!3d40.425354455112604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422863064ad3fb%3A0xe02e8b46535ae66d!2sAikido%20Ametsuchi!5e0!3m2!1ses!2ses!4v1733219021801!5m2!1ses!2ses"
							loading="lazy"
							title="Mapa de ubicación de Aikido Ametsuchi en Google Maps"></iframe>
						<a
							className="text-italic"
							href="https://www.google.com/maps?ll=40.42535,-3.705984&z=16&t=m&hl=es&gl=ES&mapclient=embed&cid=16154002047586985581">
							{loc("Ver en Google Maps")}
						</a>
					</div>
				</section>
			</main>
			<footer id="landing-footer">
				<div className="footer-content-wrapper flex-row-item">
					<div className="footer-logo">
						<img className="logo-img" src={logo} alt="" />
						<div className="flex-column no-gap">
							<span className="text-bold">ACAE</span>
							<span className="text-italic">
								{loc("Asociación Cultural Aikikai de España")}
							</span>
						</div>
					</div>
					<div className="footer-contacto flex-column gap-small">
						<p className="text-italic text-bold">
							c/ Las Minas nº 17, 28004 Madrid, España
						</p>
						<div>
							<p className="text-italic">+34 915 224 866</p>
							<p className="text-italic">{loc("Lunes a Jueves")}</p>
							<p className="text-italic text-bold">19:30h - 21:30h</p>
						</div>
						<p className="text-italic">secretaria@acae.info</p>
					</div>
					<div className="footer-menu flex-column gap-small">
						<span className="text-bold">AMETSUCHI</span>
						<nav>
							<ul>
								<li>
									<a href="#eventos">{loc("Eventos")}</a>
								</li>
								<li>
									<a href="#publicaciones">{loc("Publicaciones")}</a>
								</li>
								<li>
									<a href="#dojo-online">{loc("Dojo online")}</a>
								</li>
								<li>
									<a href="#nuestra-historia">{loc("Nuestra historia")}</a>
								</li>
								<li>
									<a href="#contacto">{loc("Contacto")}</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="footer-legal flex-row-item">
					<div className="flex-row-item">
						<p>
							{loc(
								"© 2024 Asociación Cultural Aikikai de España — Todos los derechos reservados"
							)}
						</p>
					</div>
					<div className="flex-row-item">
						<a className="footer-link" href="#politica-privacidad">
							{loc("Política de privacidad")}
						</a>
						<a className="footer-link" href="#aviso-legal">
							{loc("Aviso legal")}
						</a>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default LandingPage;
