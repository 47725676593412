import React, { useState, useRef, useContext, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import '../assets/css/modal.css';
import CursoCartelMod from "./CursoCartelMod";
//Contexto
import ConexContext from "../context/conex/ConexContext";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as NewimgIcon } from "../assets/img/iconos/acae_new_img.svg";

const CursosMod = (props) => {
	const { peticion, loc, Cargando } = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang } = useParams();

	// Obtener la fecha actual en formato YYYY-MM-DD
	const today = new Date().toISOString().split("T")[0];
	const [Imagen, setImagen] = useState(null);
	const [ClassCurso, setClassCurso] = useState('cursoCerrado');

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })

	//STATES
	const [Lectura, setLectura] = useState(false) //está en modo lectura


	const [Formu, setFormu] = useState({}) //todos los campos del formulario
	const [FormuCopy, setFormuCopy] = useState({}) //todos los campos del formulario Copia

	//REFs
	// const idTimer = useRef(null);
	const rTitulo = useRef();

	// effect de carga de datos inicial
	useEffect(() => {
		async function ver() {
			const pet = await peticion('/cursos/ver?id=' + props.show);
			if (pet.estado) //algún error 
				return;

			const sesionesOrdenadas = pet.res.aSesion.sort((a, b) => {
				if (a.tipo === b.tipo) {
					return new Date(a.fecha) - new Date(b.fecha); // Ordenar por fecha si son del mismo tipo
				}
				return a.tipo === 'S' ? -1 : 1; // Sesión ('S') antes que evento ('E')
			});

			setFormu({
				titulo: pet.res.titulo,
				ciudad: pet.res.ciudad,
				obs: pet.res.obs,
				aSesion: sesionesOrdenadas,
				imagen: pet.res.imagen
			});
			setFormuCopy({
				titulo: pet.res.titulo,
				ciudad: pet.res.ciudad,
				obs: pet.res.obs,
				aSesion: sesionesOrdenadas,
				imagen: pet.res.imagen
			});

			if (pet.res.cfValDojo !== 0) setLectura(true);
		}

		if (props.show > 0)
			ver();
		else if (props.show < 0) {
			setFormu({
				titulo: '',
				fecha: '',
				ciudad: '',
				obs: '',
				imagen: '',
				aSesion: [{
					fecha: '',
					horaini: '00:00',
					horafin: '23:00',
					descripcion: '',
					tipo: 'S'
				}],
			});
			rTitulo.current.focus();
		}
	}, [props.show, peticion]);


	// effect para Formu
	// useEffect(() => {
	// 	console.log(Formu)
	// }, [Formu])

	// useEffect(() => {console.log('Formu',Formu)}, [Formu]);
	// guardar el registro
	async function guarda() {
		let diffKeys = Object.keys(Formu).filter(key => Formu[key] !== FormuCopy[key] && !key.endsWith("Err"));
		if (Imagen !== null){
			diffKeys = [...diffKeys, 'imagen'];
		}
		// requeridos
		const error = {}
		if (Formu.titulo==='') error.tituloErr=loc('El título es requerido.')
		if (Formu.ciudad==='') error.ciudadErr=loc('La ciudad es requerida.')
		// if (Formu.obs==='') error.obsErr=loc('Debes poner los detalles del curso .')
		// if (Formu.fecha!=='' && !moment(Formu.fecha).isAfter(moment())) error.fechaErr=loc('La fecha debe ser futura.')
		if (Formu.aSesion.length<1) error.nSesionesErr=loc('Debe haber al menos una sesion')
		let err=''
		Formu.aSesion.forEach(el=>{
			if (el.fecha===''|el.descripcion===''|el.tipo===''){
				err=loc('La fecha, la descripción, el tipo de la sesión es obligatoria')
			}
			// Verificar si la fecha es mayor o igual al día de hoy
			const fechaSesion = new Date(el.fecha); // Convertir la fecha de la sesión a Date
			const hoy = new Date(); // Obtener el día de hoy
			hoy.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00 para solo comparar la fecha
			if (fechaSesion < hoy) {
				err = loc('La fecha de la sesión no puede ser anterior a hoy');
			}

		})
		if (err!=='') error.sesionesErr=err

		// filtros sin necesidad de peticiones
		if (Object.keys(error).length > 0) {//algún error
			Object.assign(Formu, error)
			setFormu({ ...Formu })
			return;
		}
		let pet
		if (props.show < 0)//inserción
			pet = await peticion('/cursos/ins', { method: 'POST', json: {
				ciudad: Formu.ciudad,
				titulo: Formu.titulo,
				obs: Formu.obs,
				aSesion: Formu.aSesion,
				imagen: Imagen
			} })
		else if (props.show > 0)//modificación
			pet = await peticion('/cursos/mod', {
				method: 'POST', json: {
					modificar: diffKeys,
					id: props.show,
					ciudad: Formu.ciudad,
					titulo: Formu.titulo,
					obs: Formu.obs,
					aSesion: Formu.aSesion,
					imagen: Imagen
				}
			})
		let id = pet?.res?.idCurso ?? props.show
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev) => ({ ...prev }))
		else {
			navigate(`/${lang}/app/cursos`)
			props.listar()
		}

	}
	//cierra el modal
	function cierra() {
		setFormu({})
		setFormuCopy({})
		setImagen(null);
		props.onHide()
	}

	// Incrementa o decrmenta la cantidad de sesiones
	function incrSesion(incr) {
		if (incr > 0) {
			Formu.aSesion.push({
				fecha: Formu?.aSesion.slice(-1)[0]?.fecha || '',
				horaini: '00:00',
				horafin: '23:00',
				descripcion: '',
				tipo: 'S'
			});
		} else if (Formu.aSesion.length > 1) {
			Formu.aSesion.pop();
		} else {
			return;
		}
		// setNumSesiones(Formu.aSesion.length);
		setFormu({ ...Formu, aSesion: Formu.aSesion, sesionesErr: '', nSesionesErr: '' });
	}

	if (props.show === 0)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<CursoIcon className="section-icon curso" />
							<h3>
								{props.show > 0 ? loc("Modificar curso") : loc("Nuevo curso")}
							</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div className="modal-content-scroll form-container crear-curso">
						<div className='modal-content-outer-wrapper flex-column'>
							<div className='flex-row-item underline flex-start'>
								<div className="upload-curso-img__wrapper">
									{(Formu?.imagen || Imagen) ? (
										<img
											className="upload-curso-img__img"
											alt="Imagen del Curso"
											src={
												Imagen !== null 
													? Imagen 
													: Formu?.imagen !== null
														? `/curso/${Formu?.imagen}`
														: "/curso/default.jpg"
											}
										/>
									) : (
										<div className="upload-curso-img__default">Sin cartel</div>
									)}
									<NewimgIcon className='newimg-icon' onClick={()=>{setClassCurso(ClassCurso==='cursoAbierto'?('cursoCerrado'):('cursoAbierto'))}} />
								</div>
								<div className='flex-column flex-full-width'>
									<div className='relative-container'>
										<p>{loc("Título")}*</p>
										<input
											type="text"
											style={{ width: "100%" }}
											value={Formu?.titulo ?? ""}
											ref={rTitulo}
											onChange={(self) => {
												setFormu({
													...Formu,
													titulo: self.target.value,
													tituloErr: "",
												});
											}}
											placeholder={loc("Título")}
										/>
										<p className="error">{Formu?.tituloErr}</p>
									</div>
									<div className="flex-wrapper flex-grow flex-row-item">
										<div className='relative-container'>
											<p>{loc("Ciudad")}*</p>
											<input
												value={Formu?.ciudad ?? ""}
												onChange={(self) => {
													setFormu({
														...Formu,
														ciudad: self.target.value,
														ciudadErr: "",
													});
												}}
												placeholder={loc("Ciudad")}
											/>
											<p className="error">{Formu?.ciudadErr}</p>
										</div>
										<div className='relative-container'>
											<p>{loc("Número de sesiones o eventos")}*</p>
											<div className="flex-wrapper no-gap">
												<button
													className="button primary left-radius"
													onClick={() => incrSesion(-1)}>
													-
												</button>
												<input
													className="input-calculated no-radius"
													disabled={true}
													value={(Formu?.aSesion ?? []).length}
													style={{ textAlign: "right" }}
													placeholder={loc("Sesiones")}
												/>
												<button
													className="button primary right-radius"
													onClick={() => incrSesion(1)}>
													+
												</button>
											</div>
											<p className="error">{Formu?.nSesionesErr}</p>
										</div>
									</div>
								</div>
							</div>
							
							<div className="cards-wrapper grid card">
								{(Formu?.aSesion ?? []).map((el, i) => (
									<Fragment key={i}>
										<div className="single-card-container">
											<div className={el.tipo === 'S' ? ('flex-row-item card-header space-between sesion-s'):('flex-row-item card-header space-between sesion-e')}>
												{ el.tipo === "S" ? <h4>{loc("Sesión")}</h4> : <h4>{loc("Evento")}</h4> }
												<div className='toggle-switch__wrapper' onClick={()=>{
													if (el.tipo === "S"){
														Formu.aSesion[i].tipo="E";
														setFormu({...Formu, aSesion: Formu.aSesion})
													} else {
														Formu.aSesion[i].tipo="S";
														setFormu({...Formu, aSesion: Formu.aSesion})
													}
												}}>
													<div className='toggle-switch__handle'>
													</div>
												</div>
											</div>
											<span>{loc("Fecha")}</span>
											<div>
												<input
													type="date"
													value={el.fecha || ""}
													min={today} // Establece la fecha mínima como el día de hoy
													onChange={(self) => {
														Formu.aSesion[i].fecha = self.target.value;
														for (let index = 0; index < Formu.aSesion.length; index++) {
															if (Formu.aSesion[index].fecha === ""){
																Formu.aSesion[index].fecha = self.target.value
															}
														}
														setFormu({
															...Formu,
															aSesion: Formu.aSesion,
															sesionesErr: "",
														});
													}}
												/>
											</div>
											{/* <span>{loc("Hora de inicio")}</span>
											<div>
												<input
													type="time"
													value={el.horaini || ""}
													onChange={(self) => {
														Formu.aSesion[i].horaini = self.target.value;
														setFormu({
															...Formu,
															aSesion: Formu.aSesion,
															sesionesErr: "",
														});
													}}
												/>
											</div>
											<span>{loc("Hora de fin")}</span>
											<div>
												<input
													type="time"
													value={el.horafin || ""}
													onChange={(self) => {
														Formu.aSesion[i].horafin = self.target.value;
														setFormu({
															...Formu,
															aSesion: Formu.aSesion,
															sesionesErr: "",
														});
													}}
												/>
											</div> */}
											<span>{loc("Descripción")}</span>
											<div>
												<input
													type="text"
													placeholder='"de 10:00 a 12:00" o "Cena curso" '
													value={el.descripcion || ""}
													maxLength={80} // Limita a 80 caracteres
													onChange={(self) => {
														Formu.aSesion[i].descripcion = self.target.value;
														setFormu({
															...Formu,
															aSesion: Formu.aSesion,
															sesionesErr: "",
														});
													}}
												/>
											</div>
										</div>
										<p className="error continput">{Formu.sesionesErr}</p>
									</Fragment>
								))}
							</div>

							<div>
								<p>{loc("Detalles")}</p>
							
								<textarea
									rows="5"
									style={{ width: "100%" }}
									value={Formu?.obs}
									onChange={(self) => {
										setFormu({ ...Formu, obs: self.target.value, obsErr: "" });
									}}
									placeholder={loc(
										"Detalles del curso. Profesores, recomendaciones, posibles alojamientos..."
									)}
								/>
							
								<p className="error">{Formu?.obsErr}</p>
							</div>
						</div>
					</div>

					<div className="modal-footer">
						<div className="flex-wrapper flex-end">
							<button
								type="button"
								onClick={guarda}
								className="button primary ">
								{Lectura ? loc("Guardar") : loc("Aplicar")}
								<GuardarIcon alt="Aplicar" title="Aplicar" />
							</button>
							<button className="button tertiary" onClick={cierra}>
								{loc("Cancelar")}
							</button>
						</div>
					</div>
				</div>
				<CursoCartelMod 
					show={ClassCurso}
					image={"/curso/"+Formu?.imagen}
					Imagen={Imagen}
					setImagen={setImagen}
					onHide={() => setClassCurso('cursoCerrado')}
				/>
			</main>
		);
}

export default CursosMod;