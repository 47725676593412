import React, { useContext, useEffect, useCallback, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";

import ConexContext from "./context/conex/ConexContext";

// Componentes
import Login from "./components/Login";
import Dojos from "./components/Dojos";
import Layout from "./components/Layout";
import Asocs from "./components/Asocs";
import Asociados from "./components/Asociados";
import AsociadosVista from "./components/AsociadosVista";
import { Inicio } from "./components/Inicio";
import Cuotas from "./components/Cuotas";
import Validaciones from "./components/Validaciones";
import Cursos from "./components/Cursos";
import Listado from "./components/Listado";
import LandingPage from "./components/LandingPage";

const langDisponibles = ["en", "es", "fr"];

function ValidacionLang({ children }) {
    const { lang } = useParams();

    if (!langDisponibles.includes(lang)) {
        return <Navigate to="/es" replace />;
    }

    return children;
}

function App() {
    const { token, setToken, perfil_adm_dojo, perfil_admin, perfil_adm_asoc, peticion } = useContext(ConexContext);
    const MaxTimer = 1860;
    const [WCuotas, setWCuotas] = useState(0);
    const [Sidebar, setSidebar] = useState(false);
    const [Timer, setTimer] = useState(-1);

    const guaLocalToken = useCallback(() => {
        if ('token' in localStorage && localStorage.token) {
            setToken(localStorage.token);
        }
    }, [setToken]);

    useEffect(() => {
        if (!token) guaLocalToken();
    }, [token, guaLocalToken]);

    useEffect(() => {
        const warning = async () => {
            const pet = await peticion('/cuotas/warnings');
            if (pet?.estado ?? false) setTimer(-1);
            setWCuotas(pet?.res ?? 0);
        };
        if (Timer === 0) {
            if (perfil_adm_asoc || perfil_admin) {
                warning();
            } else {
                setWCuotas(0);
            }
        }
    }, [peticion, perfil_adm_asoc, perfil_admin, Timer]);

    useEffect(() => {
        if (Timer >= 0) {
            const tp = setTimeout(() => {
                let t = Timer;
                if (t === 0) t = MaxTimer;
                setTimer(t - 1);
            }, 1000);
            return () => clearTimeout(tp);
        }
    }, [Timer]);

    const mostrarCuotas = perfil_adm_dojo || perfil_admin || perfil_adm_asoc;
    const isMobile = window.innerWidth <= 768;

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/:lang"
                    element={
                        <ValidacionLang>
                            <LandingPage />
                        </ValidacionLang>
                    }
                />
                <Route path="/" element={<Navigate to="/es" />} />
                <Route
                    path="/:lang/app/*"
                    element={
                        <ValidacionLang>
                            {!token ? (
                                <Login />
                            ) : (
                                <Layout
                                    WCuotas={WCuotas}
                                    setTimer={setTimer}
                                    Sidebar={Sidebar}
                                    setSidebar={setSidebar}
                                />
                            )}
                        </ValidacionLang>
                    }
                >
                    {!token ? (
                        <Route index element={<Login />} />
                    ) : (
                        <>
                            <Route
                                index
                                element={<Inicio Sidebar={Sidebar} setSidebar={setSidebar} />}
                            />
                            <Route
                                path="inicio"
                                element={<Inicio Sidebar={Sidebar} setSidebar={setSidebar} />}
                            />
                            <Route path="dojos/:asoc">
                                <Route
                                    index
                                    element={<Dojos Sidebar={Sidebar} setSidebar={setSidebar} />}
                                />
                                <Route
                                    path=":idReg"
                                    element={<Dojos Sidebar={Sidebar} setSidebar={setSidebar} />}
                                />
                            </Route>
                            <Route path="asociaciones" element={<Asocs Sidebar={Sidebar} setSidebar={setSidebar} />}>
                                <Route
                                    path=":idReg"
                                    element={<Asocs Sidebar={Sidebar} setSidebar={setSidebar} />}
                                />
                            </Route>
                            <Route path="asociados" element={<Navigate to={`dojo_`} />} />
                            <Route
                                path="asociados/:dojo"
                                element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar} />}
                            >
                                <Route
                                    path=":idReg"
                                    element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar} />}
                                >
                                    <Route
                                        path=":acc"
                                        element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar} />}
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="asociados/usuario/:numAcae"
                                element={
                                    <AsociadosVista Sidebar={Sidebar} setSidebar={setSidebar} />
                                }
                            />
                            {mostrarCuotas ? (
                                <Route
                                    path="cuotas"
                                    element={<Cuotas setTimer={setTimer} Sidebar={Sidebar} setSidebar={setSidebar} />}
                                >
                                    <Route
                                        path=":idReg"
                                        element={
                                            <Cuotas setTimer={setTimer} Sidebar={Sidebar} setSidebar={setSidebar} />
                                        }
                                    />
                                </Route>
                            ) : (
                                <Route
                                    path="cuotas/*"
                                    element={<Navigate to={`/es/app/inicio`} replace />}
                                />
                            )}
                            <Route path="validaciones" element={<Validaciones />}>
                                <Route path=":idReg" element={<Validaciones />} />
                            </Route>
                            <Route
                                path="cursos"
                                element={<Cursos Sidebar={Sidebar} setSidebar={setSidebar} />}
                            >
                                <Route
                                    path=":idReg"
                                    element={<Cursos Sidebar={Sidebar} setSidebar={setSidebar} />}
                                />
                            </Route>
                            <Route
                                path="listado"
                                element={
                                    !isMobile ? (
                                        <Listado />
                                    ) : (
                                        <Navigate to="/es/app/inicio" />
                                    )
                                }
                            >
                                <Route
                                    path=":idReg"
                                    element={
                                        !isMobile ? (
                                            <Listado />
                                        ) : (
                                            <Navigate to="/es/app/inicio" />
                                        )
                                    }
                                />
                            </Route>
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
