import React from "react";

import { ReactComponent as MenuIcon } from "../../assets/img/iconos/acae_menu.svg";

const MenuToggle = ({ setSidebar, Sidebar }) => {


	// const menuToggle = () => {
	// 	const menu = document.getElementById('sidebar');
	// 	return () => {
	// 		menu.classList.toggle('active-sidebar');
	// 	}
	// }



	return (
		<div className="menu-toggle mobile">
			<button onClick={()=>setSidebar(!Sidebar)}>
				<MenuIcon className="menu-icon" />
			</button>
		</div>
	);
};

export default MenuToggle;
