import React, { useState, useRef, useContext, useEffect } from 'react';
// import { useNavigate, useParams } from "react-router-dom";

import { useHotkeys } from 'react-hotkeys-hook';

import '../assets/css/modal.css';
import validErr from '../assets/js/valida'

//Contexto
import ConexContext from "../context/conex/ConexContext";

// import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as CamposIcon } from "../assets/img/layout.svg"; //Cambiar por icono de plantillas

//imágenes
// import close2 from "../assets/img/close2.svg"
// import logomas from "../assets/img/iconos/acae_dojo.svg"
import save from "../assets/img/iconos/acae_save.svg"

const PlantillasMod = (props) => {
	const {
		peticion,
		// perfil_admin,
		// perfil_asoc,
		loc } = useContext(ConexContext)
	// const navigate = useNavigate();
	// const { lang } = useParams();

	//STATES
	const [ErrNombre, setErrNombre] = useState('')// Error en el nombre
	const [ErrDescripcion, setErrDescripcion] = useState('')// Error en el nombre
	// const [plantilla, setPlantilla] = useState(null)

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 })

	//REFs
	const rId = useRef();
	const rNombre = useRef();
	const rDescripcion = useRef();

	//EFFECT
	useEffect(() => {
		
	}, [])

	/**
	 * Añade una plantilla a partir de su indice
	 * @returns 
	 */
	// const anadirPlantilla = async () => {
	// 	const pet = await peticion(`/listado/addPlantilla?nombre=${plantilla.nombre}&descripcion=${plantilla.descripcion}&defecto=${plantilla.defecto}&app=${props.conf['vista']}`, { method: 'POST', json: plantilla.plantilla })
	// 	if (pet.estado) //algún error
	// 		return;
	// 	//actualizo las plantillas
	// 	setPlantilla(null)
	// }
	// guardar el registro
	async function guarda() {
		//filtros sin necesidad de peticiones
		let error = false
		//requeridos
		error |= validErr(rNombre.current.value === '', setErrNombre, loc('El nombre es requerido.'))

		if (error)//algún error
			return;
		//filtros con peticiones
		let pet
		//comprobamos que nombre no está repetido
		pet = await peticion(`/listado/listPlantillas?app=${props.conf['vista']}`, { method: 'GET' })
		if (pet.estado) //algún error
			return;
		if (!pet?.estado ?? 1)//estado OK
			pet.res.res.forEach((plant) => {
				if (plant.nombre===rNombre.current.value){
					error=true
					setErrNombre(loc('Ya existe una plantilla con ese nombre.'))
					return;
				}
			})
		if(!error){//inserción
			pet = await peticion(`/listado/addPlantilla?nombre=${rNombre.current.value}&descripcion=${rDescripcion.current.value}&defecto=${false}&app=${props.conf['vista']}`, { method: 'POST', json: {
				campos: props.conf.campos,
				filtros: props.conf.filtros,
				agregaciones: props.conf.agregaciones
			} })
			if (pet.estado)//algún error 
				return;
			cierra()
		}
	}
	//cierra el modal
	function cierra() {
		setErrNombre('')
		setErrDescripcion('')
		props.onHide()
	}


	// if (!perfil_admin && !perfil_adm_asoc)
	// 	return ("")
	if (props.show === false)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<CamposIcon className='section-icon dojos' />
							<h3>{loc('Añadir Plantilla')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>
					<div className='modal-content-scroll'>
						<form className='form-container'>
							<input ref={rId} type='hidden' />
							<p>{loc('Nombre')}:</p>
							<input ref={rNombre}
								onChange={() => setErrNombre('')}
								placeholder={loc('Nombre de la plantilla')}
								maxLength={50} />
							<p className='error'>{ErrNombre}</p>
							<p>{loc('Descripción')}:</p>
							<input ref={rDescripcion}
								onChange={() => setErrDescripcion('')}
								placeholder={loc('Descripción de la plantilla')}
								maxLength={50} />
							<p className='error'>{ErrDescripcion}</p>
						</form>
					</div>
					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							<button
								type="button"
								onClick={guarda}
								className="button primary">{loc('Aplicar')}
								<GuardarIcon src={save} alt='Aplicar' title='Aplicar' />
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
							<p className='error'></p>
						</div>
					</div>
				</div>
			</main >

		);
}

export default PlantillasMod;