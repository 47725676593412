import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../assets/css/modal.css";

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as InscribirIcon } from "../assets/img/iconos/acae_sign.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as SearchIcon } from "../assets/img/iconos/acae_zoom.svg";
import { ReactComponent as PagoIcon } from "../assets/img/iconos/acae_money.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AsistenciaIcon } from "../assets/img/iconos/acae_kimono.svg";
import { ReactComponent as CheckIcon } from "../assets/img/iconos/acae_check.svg";
import { ReactComponent as DownloadIcon } from "../assets/img/download.svg";
import { ReactComponent as BeltIcon } from "../assets/img/iconos/acae_belt.svg";

import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";
import CursoImagenMod from "./CursoImagenMod";

const InscripcionMod = (props) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_dojo,
		perfil_dojo,
		peticionPDF,
	} = useContext(ConexContext);
	const { lang } = useParams();
	const { idAsociado, caller } = props;
	const [Curso, setCurso] = useState(null); // cursos seleccionado
	const [AlumnosList, setAlumnosList] = useState([]); // alumnos lista
	const [Filtro, setFiltro] = useState(""); // filtro para busqueda
	const [selectId, setSelectId] = useState(idAsociado);
	// Estado para manejar el orden (por nombre y si es ascendente o descendente)
	const [ordenList, setOrdenList] = useState({
		campo: "nombre",
		direccion: "asc",
	});
	const [selectAsociado, setSelectAsociado] = useState({
		nombre: "",
		apellido: "",
		numAcae: "",
	}); // Asociado a inscribir
	const [sesiones, setSesiones] = useState([]); // Sesiones seleccionadas
	const [guardado, setguardado] = useState(false);
	const [verMas, setVerMas] = useState(false);
	const [defaultAsociado, setDefaultAsociado] = useState(true);
	const [verImagen, setVerImagen] = useState(false);
	const [cerrado, setCerrado] = useState(true);
	const [skeleton, setSkeleton] = useState(false);
	const [inscritos, setinscritos] = useState(0);
	const [pagados, setpagados] = useState(0);
	const [asistidos, setasistidos] = useState(0);
	const [nasisevento, setNasisevento] = useState([{"indice":"-1", "valor":0}]);
	const [debouncedValue, setDebouncedValue] = useState([...nasisevento]);
	const prevDebouncedValue = useRef([...debouncedValue]); // Referencia al valor anterior
	const [eventToUpdate, setEventToUpdate] = useState(null); // Estado para manejar el evento pendiente de actualización
	const searchBarRef = useRef();
	const tableContainerRef = useRef(null);

	const meses = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];

	// Manejar debounce
	// useEffect(() => {
	// console.log(nasisevento)
	// console.log(nasisevento.find(item => item.indice === "62"))
	// }, [nasisevento]);

	// Manejar debounce
	useEffect(() => {
		const timers = nasisevento.map((item) =>
		setTimeout(() => {
			setDebouncedValue((prev) => {
			const indexToUpdate = prev.findIndex((p) => p.indice === item.indice);
			if (indexToUpdate !== -1 && prev[indexToUpdate].valor === item.valor) {
				return prev; // Evitar actualización innecesaria
			}
			const newValues = [...prev];
			if (indexToUpdate !== -1) {
				newValues[indexToUpdate] = item;
			} else {
				newValues.push(item); // Agregar nuevos elementos si no existen
			}
			return newValues;
			});
		}, 1000)
		);
		return () => timers.forEach((timer) => clearTimeout(timer)); // Limpiar temporizadores
	}, [nasisevento]);
	
	// Llamar a la API cuando `debouncedValue` cambia
	useEffect(() => {
		debouncedValue.forEach((item) => {
		const prevItem = prevDebouncedValue.current.find(
			(p) => p.indice === item.indice
		);
		if (!prevItem || prevItem.valor !== item.valor) {
			// Actualizar referencia
			prevDebouncedValue.current = prevDebouncedValue.current.map((p) =>
			p.indice === item.indice ? item : p
			);
			// Llamar a la API solo si `valor` es distinto de 0
			if (Curso && item.valor !== 0) {
			inscribir_evento(item.indice, item.valor); // Llamar a la API con el ID del evento y el nuevo valor
			}
		}
		});
		// eslint-disable-next-line
	}, [debouncedValue]);

	//EFFECT
	useEffect(() => {
		async function ver() {
			const curso = await peticion("/cursos/ver?id=" + props.show);
			setCurso(curso.res);
			setNasisevento(
				curso.res?.aSesion?.filter((elem) => elem.tipo === "E").map((evento) => ({
					indice: evento.idses,
					valor: 0,
				  }))
			)
			if (perfil_adm_dojo || perfil_admin) {
				// Busqueda por defecto
				const alumnos = await peticion(
					`/cursos/listAlum?filtro=${""}&cod=${curso?.res?.cfOrganiz}&curso=${
						curso?.res?.idCurso
					}&`
				);
				if (alumnos.estado === 0) {
					setAlumnosList(alumnos.res);
					setinscritos(alumnos.inscritos);
					setpagados(alumnos.pagados);
					setasistidos(alumnos.asistidos);
				}
			}
		}

		if (props.show > 0) {
			ver();
			if (perfil_adm_dojo || perfil_admin) {
				searchBarRef.current.focus();
			}
		}
	}, [props.show, peticion, perfil_admin, perfil_adm_dojo]);

	useEffect(() => {
		if (Curso) {
			if (new Date(Curso.fechaFin) >= new Date()) {
				setCerrado(false);
			} else {
				setCerrado(true);
			}
		}
		if (!perfil_adm_dojo && !perfil_admin) {
			setDefaultAsociado(false);
		}
		// eslint-disable-next-line
	}, [props.show, Curso]);

	useEffect(() => {
		if (caller === "cursos" && defaultAsociado) {
			setSkeleton(true);
		} else {
			setSkeleton(false);
		}
	}, [defaultAsociado, caller]);

	useEffect(() => {
		async function verAsociado() {
			if (Curso) {
				peticion(
					"/cursos/verAsociado?id=" + selectId + "&Cursoid=" + Curso?.idCurso
				).then((pet) => {
					if (pet.estado === 0) {
						setSelectAsociado(pet.res);
						if (pet.res?.nasisevento){
							prevDebouncedValue.current=pet.res?.nasisevento
							setNasisevento(pet.res?.nasisevento);
							setDebouncedValue(pet.res?.nasisevento);
						} 
					}
				});
				const asocSesion = await peticion("/cursos/asocSesion", {
					method: "POST",
					json: {
						idCurso: Curso?.idCurso,
						idAsoc: selectId,
					},
				});
				setSesiones(asocSesion.res.asocSesion);
			}
		}
		if (props.show === 0) return;
		verAsociado();
		// eslint-disable-next-line
	}, [Curso, props.show, peticion]);

	useEffect(() => {
		if (caller !== "cursos") {
			setFiltro(selectAsociado?.numAcae);
		}
		// eslint-disable-next-line
	}, [selectAsociado]);

	useEffect(() => {
		async function buscar() {
			if (perfil_adm_dojo || perfil_admin) {
				// Pendiente de desarrollar
				const alumnos = await peticion(
					`/cursos/listAlum?filtro=${Filtro}&cod=${Curso?.cfOrganiz}&curso=${Curso?.idCurso}&`
				);
				setAlumnosList(alumnos.res);
				setinscritos(alumnos.inscritos);
				setpagados(alumnos.pagados);
				setasistidos(alumnos.asistidos);
			}
		}

		if (props.show === 0 || Curso === null) return;
		buscar();
		// eslint-disable-next-line
	}, [Filtro]);

	useEffect(() => {
		// Si hay un evento pendiente de actualización, hacer la llamada a la API
		if (eventToUpdate) {
		const { idses, value } = eventToUpdate;
		inscribir_evento(idses, value);
		console.log(`El valor del índice ${idses} será ${value}.`);
		setEventToUpdate(null); // Limpiar el evento pendiente después de procesarlo
		}
	// eslint-disable-next-line 
	}, [eventToUpdate]);

	async function modificar(select_id) {
		try {
			peticion(
				"/cursos/verAsociado?id=" + select_id + "&Cursoid=" + Curso?.idCurso
			).then((pet) => {
				if (pet.estado === 0) {
					setSelectAsociado(pet.res);
					if (pet.res?.nasisevento){
						prevDebouncedValue.current=pet.res?.nasisevento
						setNasisevento(pet.res?.nasisevento);
						setDebouncedValue(pet.res?.nasisevento);
					} 
				}
			});
			const asocSesion = await peticion("/cursos/asocSesion", {
				method: "POST",
				json: {
					idCurso: Curso?.idCurso,
					idAsoc: select_id,
				},
			});
			setSesiones(asocSesion.res.asocSesion);
			setDefaultAsociado(false);
		} catch (error) {
			console.error("Error inesperado al cargar datos de usuario:", error);
		}
	}

	async function inscribir_evento(idsesion, nInvitados) {
		try {
			peticion(
				"/cursos/inscribirevento", {
					method: "POST",
					json: {
						idCurso: Curso?.idCurso,
						idAsoc: selectId,
						idsesion: idsesion,
						nasisevento: nInvitados,
					},
				}
			).then((pet) => {
				if (pet.estado === 0) {
					peticion(
						"/cursos/verAsociado?id=" + selectId + "&Cursoid=" + Curso?.idCurso
					).then((pet) => {
						if (pet.estado === 0) {
							setSelectAsociado(pet.res);
							if (pet.res?.nasisevento){
								prevDebouncedValue.current=pet.res?.nasisevento
								setNasisevento(pet.res?.nasisevento);
								setDebouncedValue(pet.res?.nasisevento);
							} 
						}
					});
					peticion(
						"/cursos/asocSesion", {
						method: "POST",
						json: {
							idCurso: Curso?.idCurso,
							idAsoc: selectId,
						},
					}).then((pet) => {
						if (pet.estado === 0) {
							setSesiones(pet.res.asocSesion);
						}
					});
				}
			});
			// const inscribir = await peticion("/cursos/inscribirevento", {
			// 	method: "POST",
			// 	json: {
			// 		idCurso: Curso?.idCurso,
			// 		idAsoc: selectId,
			// 		idsesion: idsesion,
			// 		nasisevento: nInvitados,
			// 	},
			// });
			// peticion(
			// 	"/cursos/verAsociado?id=" + selectId + "&Cursoid=" + Curso?.idCurso
			// ).then((pet) => {
			// 	if (pet.estado === 0) {
			// 		setSelectAsociado(pet.res);
			// 		if (pet.res?.nasisevento){
			// 			prevDebouncedValue.current=pet.res?.nasisevento
			// 			setNasisevento(pet.res?.nasisevento);
			// 			setDebouncedValue(pet.res?.nasisevento);
			// 		} 
			// 	}
			// });
			// const asocSesion = await peticion("/cursos/asocSesion", {
			// 	method: "POST",
			// 	json: {
			// 		idCurso: Curso?.idCurso,
			// 		idAsoc: selectId,
			// 	},
			// });
			// setSesiones(asocSesion.res.asocSesion);
			setguardado(true);
			// if (perfil_adm_dojo || perfil_admin) {
			// 	const alumnos = await peticion(
			// 		`/cursos/listAlum?filtro=${Filtro}&cod=${
			// 			Curso?.cfOrganiz
			// 		}&curso=${Curso?.idCurso}&`
			// 	);
			// 	setAlumnosList(alumnos.res);
			// 	setinscritos(alumnos.inscritos);
			// 	setpagados(alumnos.pagados);
			// 	setasistidos(alumnos.asistidos);
			// 	setDefaultAsociado(false);
			// }
			setTimeout(() => {
				setguardado(false);
			}, 3000);
			if (inscribir.estado === 0) {
				return null;
			}
		} catch (error) {
			console.error("Error inesperado al inscribir:", error);
		}
	}

	async function inscribir(sesiones_aux, select_id, filtro) {
		try {
			if (select_id) {
				peticion(
					"/cursos/verAsociado?id=" + select_id + "&Cursoid=" + Curso?.idCurso
				).then((pet) => {
					if (pet.estado === 0) {
						setSelectAsociado(pet.res);
						if (pet.res?.nasisevento){
							prevDebouncedValue.current=pet.res?.nasisevento
							setNasisevento(pet.res?.nasisevento);
							setDebouncedValue(pet.res?.nasisevento);
						} 
					}
				});
			}
			if (perfil_adm_dojo || perfil_admin) searchBarRef.current.focus();
			const inscribir = await peticion("/cursos/inscribir", {
				method: "POST",
				json: {
					idCurso: Curso.idCurso,
					idAsoc: select_id ? select_id : selectId,
					aSesion: sesiones_aux,
				},
			});
			if (select_id) {
				peticion(
					"/cursos/verAsociado?id=" + select_id + "&Cursoid=" + Curso?.idCurso
				).then((pet) => {
					if (pet.estado === 0) {
						setSkeleton(false);
						setSelectAsociado(pet.res);
						if (pet.res?.nasisevento){
							prevDebouncedValue.current=pet.res?.nasisevento
							setNasisevento(pet.res?.nasisevento);
							setDebouncedValue(pet.res?.nasisevento);
						} 
					}
				});
			}
			setguardado(true);
			if (perfil_adm_dojo || perfil_admin) {
				const alumnos = await peticion(
					`/cursos/listAlum?filtro=${filtro ? filtro : Filtro}&cod=${
						Curso?.cfOrganiz
					}&curso=${Curso?.idCurso}&`
				);
				setAlumnosList(alumnos.res);
				setinscritos(alumnos.inscritos);
				setpagados(alumnos.pagados);
				setasistidos(alumnos.asistidos);
				setDefaultAsociado(false);
			}
			setTimeout(() => {
				setguardado(false);
			}, 3000);
			if (inscribir.estado === 0) {
				return null;
			}
		} catch (error) {
			console.error("Error inesperado al inscribir:", error);
		}
	}

	const validarAlumno = async (tipo, elem, valor) => {
		const validar = await peticion("/cursos/alumnoVal", {
			method: "POST",
			json: {
				tipo: tipo,
				idCurso: Curso.idCurso,
				idAsoc: elem.idAso,
				valor: valor,
				cfOrganiz: Curso.cfOrganiz,
			},
		});
		setguardado(true);
		if (tipo === "Asistencia") {
			setAlumnosList((prev) =>
				prev.map((alumno) =>
					alumno.idAso === elem.idAso
						? { ...alumno, asistencia: valor }
						: alumno
				)
			);
		} else {
			setAlumnosList((prev) =>
				prev.map((alumno) =>
					alumno.idAso === elem.idAso ? { ...alumno, pagado: valor } : alumno
				)
			);
		}
		peticion(
			"/cursos/verAsociado?id=" + selectId + "&Cursoid=" + Curso?.idCurso
		).then((pet) => {
			if (pet.estado === 0) {
				setSelectAsociado(pet.res);
				if (pet.res?.nasisevento){
					prevDebouncedValue.current=pet.res?.nasisevento
					setNasisevento(pet.res?.nasisevento);
					setDebouncedValue(pet.res?.nasisevento);
				} 
			}
		});
		setTimeout(() => {
			setguardado(false);
		}, 3000);
		if (validar.estado === 0) {
			return null;
		}
	};

	// Función para manejar el cambio de orden (cambiar de ascendente a descendente)
	const handleOrdenar = (campo) => {
		setOrdenList((prevOrden) => {
			if (prevOrden.campo === campo) {
				// Si ya estamos ordenando por el mismo campo, cambiar la dirección
				return {
					campo,
					direccion: prevOrden.direccion === "asc" ? "desc" : "asc",
				};
			}
			// Si estamos ordenando por un campo diferente, establecer ascendente por defecto
			return { campo, direccion: "asc" };
		});
	};

	const PeticionPlantilla = async () => {
		try {
			peticionPDF(`/cursos/descargar_plantilla`, { method: "POST" }).then(
				async (res) => {
					if (!res) {
						Swal.fire({
							title: "No se ha encontrado la plantilla",
							text: "La plantilla no existe en el servidor",
							showConfirmButton: true,
							icon: "error",
							heightAuto: false,
						});
						return;
					}
				}
			);
		} catch (error) {
			console.error("Error al descargar el archivo", error);
		}
	};

	const PeticionExcel = async () => {
		try {
			peticionPDF(
				`/cursos/descargar_alumnosExcel?&cod=${Curso?.cfOrganiz}&curso=${
					Curso?.idCurso
				}&nombre=${`${Curso?.ciudad} ${
					meses[parseInt(Curso?.fechaFin.split("-")[1]) - 1]
				} ${Curso?.fechaFin.split("-")[0]}`}&`,
				{ method: "POST" }
			).then(async (res) => {
				if (!res) {
					Swal.fire({
						title: "Listado erróneo",
						text: "Ha habido un problema en el servidor",
						showConfirmButton: true,
						icon: "error",
						heightAuto: false,
					});
					return;
				}
			});
		} catch (error) {
			console.error("Error al descargar el archivo", error);
		}
	};

	//cierra el modal
	function cierra() {
		setCurso(null);
		setOrdenList({ campo: "nombre", direccion: "asc" });
		setFiltro("");
		setAlumnosList([]);
		setinscritos(0);
		setpagados(0);
		setasistidos(0);
		setSesiones([]);
		setguardado(false);
		setDefaultAsociado(true);
		setSelectId(idAsociado);
		setVerMas(false);
		setSelectAsociado({
			nombre: "",
			apellido: "",
			numAcae: "",
		});
		props.onHide();
	}

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<InscribirIcon className="section-icon asociados" />
							<h3>{loc("Inscripción")}</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div
						className="modal-content-scroll inscripciones"
						ref={tableContainerRef}>
						<div className="modal-content-outer-wrapper">
							{Curso && (
								<div className="inscribir-asociado-container--row underline">
									<div className="inscribir-asociado__img">
										{Curso?.imagen ? (
											<img
												className="curso-img"
												style={{ cursor: "pointer" }}
												src={
													Curso?.imagen !== null
														? `/curso/${Curso?.imagen}`
														: "/curso/default.jpg"
												}
												alt="Curso"
												onClick={() => {
													setVerImagen(true);
												}}
											/>
										) : (
											<div className="upload-curso-img__default">
												Sin cartel
											</div>
										)}
									</div>
									<div className="flex-column gap-small grow">
										<div className="flex-column gap-small">
											<div className="flex-row-item gap-small">
												<h4>{Curso?.titulo}</h4>
												<div className="flex-row-item gap-small location">
													<CursoIcon className="section-icon" />
													<span>{Curso?.ciudad}</span>
												</div>
											</div>
											<div className="flex-row-item date">
												<span>
													{new Date(Curso?.fechaIni)
														.toLocaleDateString(
															lang + "-" + lang.toUpperCase(),
															{
																day: "2-digit",
																month: "short",
																year: "numeric",
															}
														)
														.toUpperCase()}
												</span>
												-
												<span>
													{new Date(Curso?.fechaFin)
														.toLocaleDateString(
															lang + "-" + lang.toUpperCase(),
															{
																day: "2-digit",
																month: "short",
																year: "numeric",
															}
														)
														.toUpperCase()}
												</span>
											</div>
										</div>
										<div className="observaciones-bg">
											<p className={`observaciones ${verMas && "show"}`}>
												{Curso?.obs}
											</p>
											<button
												className="ver-mas__button"
												onClick={() => setVerMas(!verMas)}>
												{verMas ? loc("Ver menos") : loc("Ver más")}
											</button>
										</div>
									</div>
								</div>
							)}

							<div
								className={
									"inscribir-asociado-container select-sesiones " +
									(defaultAsociado &&
									caller === "cursos" &&
									(perfil_admin || perfil_adm_dojo)
										? "skeleton"
										: "")
								}>
								<div className="flex-row-item gap-small">
									<div className="user-avatar">
										<div>
											<img
												src={`/avatars/${
													defaultAsociado &&
													caller === "cursos" &&
													(perfil_admin || perfil_adm_dojo)
														? "default.jpg"
														: selectAsociado.avatar
														? selectAsociado.avatar
														: "default.jpg"
												}`}
												alt="Avatar"
												className="avatar"
											/>
										</div>
									</div>
									<h4>
										{defaultAsociado &&
										caller === "cursos" &&
										(perfil_admin || perfil_adm_dojo)
											? loc("Selecciona un asociado")
											: ""}{" "}
										{defaultAsociado &&
										caller === "cursos" &&
										(perfil_admin || perfil_adm_dojo)
											? ""
											: selectAsociado?.nombre}{" "}
										{defaultAsociado &&
										caller === "cursos" &&
										(perfil_admin || perfil_adm_dojo)
											? ""
											: selectAsociado?.apellido}
									</h4>
									{defaultAsociado && caller === "cursos" ? (
										<></>
									) : (
										<h4 className="numacae">{selectAsociado.numAcae}</h4>
									)}
									<div className="pago-asistencia-checkbox__container">
										{perfil_adm_dojo &&
											perfil_dojo === Curso?.cfOrganiz &&
											!skeleton &&
											selectAsociado?.pagado !== null && (
												<input
													id="pagado-checkbox"
													className="pago-asistencia-checkbox"
													type="checkbox"
													checked={selectAsociado.pagado}
													onChange={(e) => {
														validarAlumno(
															"Pagado",
															selectAsociado,
															e.target.checked
														);
													}}
												/>
											)}
										<label htmlFor="pagado-checkbox">
											<PagoIcon
												className={`section-icon ${
													skeleton ? "false" : selectAsociado.pagado
												}`}
												title={
													!skeleton &&
													(selectAsociado.pagado ? "Pagado" : "Pago pendiente")
												}
											/>
										</label>
									</div>
									<div className="pago-asistencia-checkbox__container">
										{perfil_adm_dojo &&
											perfil_dojo === Curso?.cfOrganiz &&
											!skeleton &&
											selectAsociado?.asistencia !== null && (
												<input
													id="asistencia-checkbox"
													className="pago-asistencia-checkbox"
													type="checkbox"
													checked={selectAsociado.asistencia}
													onChange={(e) => {
														validarAlumno(
															"Asistencia",
															selectAsociado,
															e.target.checked
														);
													}}
												/>
											)}
										<label htmlFor="asistencia-checkbox">
											<AsistenciaIcon
												className={`section-icon ${
													skeleton ? "false" : selectAsociado.asistencia
												}`}
												title={
													!skeleton &&
													(selectAsociado.asistencia
														? "Asistido"
														: "No asistido")
												}
											/>
										</label>
									</div>
									{guardado && (
										<p className="guardado">
											<CheckIcon /> {loc("Guardado")}
										</p>
									)}
								</div>
								{!skeleton && (
									<div className="secciones-lista flex-column gap-small">
										<p className="notification-text">
											{loc(
												"Selecciona las sesiones o eventos a los que vas a asistir"
											)}
										</p>

										<div className="grid card gap-small">
											{Curso?.aSesion
												?.filter((elem) => elem.tipo === "S")
												.sort((a, b) => {
													// Ordenar por 'fecha'
													if (a.fecha !== b.fecha) {
														return new Date(a.fecha) - new Date(b.fecha);
													}
													// Ordenar por 'idses'
													return a.idses - b.idses;
												})
												.map((elem, i) => (
													<div
														className={`flex-column gap-small sesion ${
															sesiones.includes(elem.idses) ? "selected" : ""
														} ${
															selectAsociado.pagado ||
															selectAsociado.asistencia ||
															(defaultAsociado &&
																caller === "cursos" &&
																(perfil_admin || perfil_adm_dojo)) ||
															(cerrado &&
																!(
																	perfil_adm_dojo &&
																	perfil_dojo === Curso?.cfOrganiz
																))
																? "disabled"
																: ""
														}`}
														key={i}
														onClick={() => {
															if (
																selectAsociado.pagado ||
																selectAsociado.asistencia ||
																(defaultAsociado &&
																	caller === "cursos" &&
																	(perfil_admin || perfil_adm_dojo)) ||
																(cerrado &&
																	!(
																		perfil_adm_dojo &&
																		perfil_dojo === Curso?.cfOrganiz
																	))
															)
																return;

															if (sesiones.includes(elem.idses)) {
																const nuevasSesiones = sesiones.filter(
																	(s) => s !== elem.idses
																);
																setSesiones(nuevasSesiones);
																inscribir(nuevasSesiones);
															} else {
																const nuevasSesiones = [
																	...sesiones,
																	elem.idses,
																];
																setSesiones(nuevasSesiones);
																inscribir(nuevasSesiones);
															}
														}}>
														<div className="flex-row-item space-between">
															<div className="flex-row-item gap-small">
																<BeltIcon className="sesion-card-icon" />
																<span className="tipo-sesion">
																	{loc("Sesión")}
																</span>
															</div>
														</div>
														<div>
															<div>
																{new Intl.DateTimeFormat(
																	lang + "-" + lang.toUpperCase(),
																	{
																		day: "numeric",
																		month: "long",
																		year: "numeric",
																	}
																)
																	.format(new Date(elem.fecha))
																	.toUpperCase()}
															</div>
															<div className="flex-row-item gap-small space-between">
																<div className="date">{elem?.descripcion}</div>
																<div className="checkbox" />
															</div>
														</div>
													</div>
												))}

											{Curso?.aSesion
												?.filter((elem) => elem.tipo === "E")
												.sort((a, b) => {
													// Ordenar por 'fecha'
													if (a.fecha !== b.fecha) {
														return new Date(a.fecha) - new Date(b.fecha);
													}
													// Ordenar por 'idses'
													return a.idses - b.idses;
												})
												.map((elem, i) => (
													<div
														className={`flex-column gap-small sesion sesion-evento  ${
															sesiones.includes(elem.idses) ? "selected" : ""
														} ${
															selectAsociado.pagado ||
															selectAsociado.asistencia ||
															(defaultAsociado &&
																caller === "cursos" &&
																(perfil_admin || perfil_adm_dojo)) ||
															(cerrado &&
																!(
																	perfil_adm_dojo &&
																	perfil_dojo === Curso?.cfOrganiz
																))
																? "disabled"
																: ""
														}`}
														key={i}
														onClick={() => {
															if (
																selectAsociado.pagado ||
																selectAsociado.asistencia ||
																(defaultAsociado &&
																	caller === "cursos" &&
																	(perfil_admin || perfil_adm_dojo)) ||
																(cerrado &&
																	!(
																		perfil_adm_dojo &&
																		perfil_dojo === Curso?.cfOrganiz
																	))
															)
																return;

															if (sesiones.includes(elem.idses)) {
																const nuevasSesiones = sesiones.filter(
																	(s) => s !== elem.idses
																);
																setSesiones(nuevasSesiones);
																inscribir_evento(elem.idses, 0); // Llamar a la API con el ID del evento y el nuevo valor
																// inscribir(nuevasSesiones);
															} else {
																const nuevasSesiones = [
																	...sesiones,
																	elem.idses,
																];
																setSesiones(nuevasSesiones);
																inscribir_evento(elem.idses, 1); // Llamar a la API con el ID del evento y el nuevo valor
																// inscribir(nuevasSesiones);
															}
														}}>
														<div className="flex-row-item space-between">
															<div className="flex-row-item gap-small">
																<AsistenciaIcon className="sesion-card-icon" />
																<span className="tipo-sesion">
																	{loc("Evento")}
																</span>
															</div>
															<div className="relative-container">
																<div className="flex-wrapper no-gap">
																	<button
																		className={`button contrast left-radius ${
																			selectAsociado.pagado ||
																			selectAsociado.asistencia ||
																			(defaultAsociado &&
																				caller === "cursos" &&
																				(perfil_admin || perfil_adm_dojo)) ||
																			(cerrado &&
																				!(
																					perfil_adm_dojo &&
																					perfil_dojo === Curso?.cfOrganiz
																				))
																				? "disabled"
																				: ""
																		}`}
																		onClick={(e) => {
																			e.stopPropagation();

																			if (
																				selectAsociado.pagado ||
																				selectAsociado.asistencia ||
																				(defaultAsociado &&
																					caller === "cursos" &&
																					(perfil_admin || perfil_adm_dojo)) ||
																				(cerrado &&
																					!(
																						perfil_adm_dojo &&
																						perfil_dojo === Curso?.cfOrganiz
																					))
																			)
																				return;
				
																			// setNasisevento(
																			// 	(prev) =>
																			// 		prev.map((val, index) => (index === i && val > 0 ? val - 1 : val))
																			// );
																			setNasisevento((prev) =>
																				prev.map((val) => {
																					if (val.indice === (elem.idses).toString()) {
																						if (val.valor === 1) {
																							// Guardar el evento a actualizar
																							setEventToUpdate({
																								idses: elem?.idses,
																								value: 0
																							});
																						}
																						return { ...val, valor: val.valor > 0 ? val.valor - 1 : 0 };
																					}
																					return val;
																				})
																			  );
																			// if(nasisevento[i] >1){
																			// 	inscribir_evento(elem.idses, nasisevento[i]-1);
																			// }
																			
																		}}></button>
																	<input
																		className="input-calculated no-radius"
																		disabled={true}
																		style={{ textAlign: "right" }}
																		value={	
																			// selectAsociado?.nasisevento[i] || 
																			nasisevento.find(item => item.indice === (elem.idses).toString())?.valor
																			// nasisevento[i]
																		}
																	/>
																	<button
																		className={`button contrast right-radius ${
																			selectAsociado.pagado ||
																			selectAsociado.asistencia ||
																			(defaultAsociado &&
																				caller === "cursos" &&
																				(perfil_admin || perfil_adm_dojo)) ||
																			(cerrado &&
																				!(
																					perfil_adm_dojo &&
																					perfil_dojo === Curso?.cfOrganiz
																				))
																				? "disabled"
																				: ""
																		}`}
																		onClick={(e) => {
																			e.stopPropagation();
																			if (
																				selectAsociado.pagado ||
																				selectAsociado.asistencia ||
																				(defaultAsociado &&
																					caller === "cursos" &&
																					(perfil_admin || perfil_adm_dojo)) ||
																				(cerrado &&
																					!(
																						perfil_adm_dojo &&
																						perfil_dojo === Curso?.cfOrganiz
																					))
																			)
																				return;
				
																			setNasisevento(
																				(prev) =>
																					prev.map((val) => (val.indice === (elem.idses).toString() ? { ...val, valor: val.valor + 1 } : val))
																			);
																			// inscribir_evento(elem.idses, nasisevento[i]+1);
																		}}></button>
																</div>
															</div>
														</div>
														<div>
															<div>
																{new Intl.DateTimeFormat(
																	lang + "-" + lang.toUpperCase(),
																	{
																		day: "numeric",
																		month: "long",
																		year: "numeric",
																	}
																)
																	.format(new Date(elem.fecha))
																	.toUpperCase()}
															</div>
															<div className="flex-row-item gap-small space-between">
																<div className="date">{elem?.descripcion}</div>
																<div className="checkbox" />
															</div>
														</div>
													</div>
												))}
										</div>

										{sesiones.length > 0 && !skeleton && (
											<p className="guardado red">
												<CheckIcon />{" "}
												{loc(
													"Inscrito en " +
														sesiones.length +
														" de " +
														Curso.aSesion.length +
														" sesiones o eventos"
												)}
											</p>
										)}
										{(selectAsociado.pagado || selectAsociado.asistencia) &&
											!skeleton && (
												<p className="guardado red">
													<CloseIcon />
													{loc(
														"No se puede modificar la inscripción de un socio que ya ha pagado o asistido"
													)}
												</p>
											)}
									</div>
								)}

								{!skeleton && (perfil_adm_dojo || perfil_admin) && (
									<button
										className="button cta inscribir-otro-asociado"
										onClick={() => {
											setDefaultAsociado(true);
											setFiltro("");
											searchBarRef.current.focus();
										}}>
										{loc("Inscribir otro asociado")}
									</button>
								)}
							</div>

							{perfil_admin || perfil_adm_dojo ? (
								<div className="flex-row-item space-between">
									<div className="flex-row-item gap-small no-padding">
										<div className="inscribir-asociado-container search">
											<form>
												<input
													className="asociado-search"
													ref={searchBarRef}
													value={Filtro} // valor inicial
													type="search"
													placeholder={loc("Buscar")}
													onChange={(e) => setFiltro(e.target.value)}
												/>
												{Filtro === "" ? (
													<SearchIcon alt="" title="" />
												) : (
													<CloseIcon
														className="red-fill"
														onClick={() => {
															setFiltro("");
															searchBarRef.current.focus();
														}}
														alt=""
														title=""
													/>
												)}
											</form>
										</div>
										{perfil_adm_dojo || perfil_admin ? (
											<div className="flex-row-item no-gap estado-container__count">
												<PagoIcon />
												<span>
													{pagados}
													{"/"}
												</span>
												<span className="text-bold">{inscritos}</span>
											</div>
										) : null}
										{perfil_adm_dojo || perfil_admin ? (
											<div className="flex-row-item no-gap estado-container__count">
												<AsistenciaIcon />
												<span>
													{asistidos}
													{"/"}
												</span>
												<span className="text-bold">{inscritos}</span>
											</div>
										) : null}
									</div>
									{perfil_adm_dojo && perfil_dojo === Curso?.cfOrganiz && (
										<div className="flex-row-item gap-small">
											<button
												className="button primary"
												onClick={() => {
													PeticionExcel();
												}}>
												<DownloadIcon /> {loc("Listado de inscripciones")}
											</button>
											<button
												className="button primary"
												onClick={() => {
													PeticionPlantilla();
												}}>
												<DownloadIcon /> {loc("Plantilla vacía")}
											</button>
										</div>
									)}
								</div>
							) : null}
						</div>

						{perfil_admin || perfil_adm_dojo ? (
							<div className="table-container">
								<div className="table-headers">
									<div className="table-headers--tr">
										{((perfil_adm_dojo && perfil_dojo === Curso?.cfOrganiz) ||
											perfil_admin) && (
											<div
												className="table-headers--th tiny-column"
												title="Pago">
												<PagoIcon />
											</div>
										)}
										{((perfil_adm_dojo && perfil_dojo === Curso?.cfOrganiz) ||
											perfil_admin) && (
											<div
												className="table-headers--th tiny-column"
												title="Asistencia">
												<AsistenciaIcon />
											</div>
										)}
										<div className="table-headers--th wide-column">
											<button>{loc("Inscribir")}</button>
										</div>
										<div className="table-headers--th wide-column">
											<button>{loc("Num ACAE")}</button>
										</div>
										<div className="table-headers--th">
											<button onClick={() => handleOrdenar("nombre")}>
												{loc("Nombre")}
											</button>
											<ul className="listaordena flex-item-row gap-small">
												{ordenList.campo === "nombre" &&
													ordenList.direccion === "desc" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												{ordenList.campo === "nombre" &&
													ordenList.direccion === "asc" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
											</ul>
										</div>
										<div className="table-headers--th">
											<button onClick={() => handleOrdenar("apellido")}>
												{loc("Apellidos")}
											</button>
											<ul className="listaordena flex-item-row gap-small">
												{ordenList.campo === "apellido" &&
													ordenList.direccion === "desc" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												{ordenList.campo === "apellido" &&
													ordenList.direccion === "asc" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
											</ul>
										</div>
										<div className="table-headers--th">
											<button>{loc("Dojo")}</button>
										</div>
										<div className="table-headers--th wide-column">
											<button>{loc("Adulto")}</button>
										</div>
										<div className="table-headers--th wide-column">
											<button>{loc("Última cuota")}</button>
										</div>
									</div>
								</div>
								<div className="table-results table-inscripcion-mod">
									{AlumnosList &&
										AlumnosList.sort((a, b) => {
											// Prioridad: inscritos primero
											if (a.inscrito === true && b.inscrito === false)
												return -1; // 'a' va antes si está inscrito
											if (a.inscrito === false && b.inscrito === true) return 1; // 'b' va antes si está inscrito

											// Ordenar por el campo y la dirección seleccionados
											const valorA =
												a[ordenList.campo]?.toString().toLowerCase() || "";
											const valorB =
												b[ordenList.campo]?.toString().toLowerCase() || "";

											if (valorA < valorB)
												return ordenList.direccion === "asc" ? -1 : 1;
											if (valorA > valorB)
												return ordenList.direccion === "asc" ? 1 : -1;
											return 0;
										}).map((elem) => (
											<div
												className={`table-results--tr ${
													elem?.inscrito ? "apuntado" : ""
												}`}
												key={elem?.idAso}>
												{perfil_adm_dojo && perfil_dojo === Curso.cfOrganiz ? (
													<div className="table-results--td tiny-column">
														{elem?.pagado !== null && (
															<input
																type="checkbox"
																checked={elem.pagado}
																onClick={(e) => e.stopPropagation()} // Detiene el onClick del <div>
																onChange={(e) => {
																	validarAlumno(
																		"Pagado",
																		elem,
																		e.target.checked
																	);
																}}
															/>
														)}
													</div>
												) : perfil_admin ? (
													elem?.pagado === true ? (
														<div className="table-results--td tiny-column">
															<CheckIcon />
														</div>
													) : (
														<div className="table-results--td tiny-column"></div>
													)
												) : null}
												{perfil_adm_dojo && perfil_dojo === Curso.cfOrganiz ? (
													<div className="table-results--td tiny-column">
														{elem?.asistencia !== null && (
															<input
																type="checkbox"
																checked={elem.asistencia}
																onClick={(e) => e.stopPropagation()} // Detiene el onClick del <div>
																onChange={(e) => {
																	validarAlumno(
																		"Asistencia",
																		elem,
																		e.target.checked
																	);
																}}
															/>
														)}
													</div>
												) : perfil_admin ? (
													elem?.asistencia === true ? (
														<div className="table-results--td tiny-column">
															<CheckIcon />
														</div>
													) : (
														<div className="table-results--td tiny-column"></div>
													)
												) : null}
												<div
													key={elem?.idAso}
													className="table-results--td table-actions wide-column">
													<div
														className={`inscripcion estado-container ${
															cerrado
																? perfil_adm_dojo &&
																  perfil_dojo === Curso.cfOrganiz
																	? ""
																	: elem?.inscrito
																	? ""
																	: "disabled"
																: ""
														}`}
														onClick={() => {
															if (
																cerrado &&
																!elem?.inscrito &&
																!(
																	perfil_adm_dojo &&
																	perfil_dojo === Curso.cfOrganiz
																)
															)
																return;
															if (tableContainerRef.current) {
																tableContainerRef.current.scrollTo({
																	top: 0,
																	behavior: "smooth",
																});
																setFiltro(elem?.numacae);
																// setDefaultAsociado(false);
																searchBarRef.current.focus();
															}
															setSelectId(elem?.idAso);
															// if (elem?.pagado || elem?.asistencia) return;
															if (elem?.inscrito) {
																modificar(elem?.idAso);
																return;
															}
															const nuevasSesiones = Curso.aSesion
																.filter((s) => s.tipo === "S")
																.map((obj) => obj.idses);
															setSesiones(nuevasSesiones);
															inscribir(
																nuevasSesiones,
																elem?.idAso,
																elem?.numacae
															);
														}}>
														{elem?.inscrito
															? cerrado
																? perfil_adm_dojo &&
																  perfil_dojo === Curso.cfOrganiz
																	? loc("Modificar")
																	: loc("Ver")
																: loc("Modificar")
															: loc("Inscribir")}
													</div>
												</div>
												<div className="table-results--td avatar-row wide-column">
													<img
														src={"/avatars/" + (elem?.avatar ?? "default.jpg")}
														alt=""
													/>{" "}
													{elem?.numacae}
												</div>
												<div className="table-results--td table-button black ocultamovil">
													{perfil_admin ||
													(perfil_adm_dojo &&
														elem.codigo_dojo === perfil_dojo) ? (
														<div className='flex-row-item gap-small'>
															<Link to={`/${lang}/app/asociados/usuario/${elem.numacae}`} className='cell-td-button' title={loc('Ir a asociado')}>
																<ViewIcon />
															</Link>
															<span className="cell-td-button--label grow text-ellipsis">
																{elem.nombre}
															</span>
														</div>
													) : (
														<span className="grow text-ellipsis">
															{elem.nombre}
														</span>
													)}
												</div>
												<div className="table-results--td">
													{elem?.apellido}
												</div>
												<div className="table-results--td table-button black ocultamovil">
													{/* {perfil_admin || (perfil_adm_dojo && (elem.codigo_dojo === perfil_dojo)) ? */}
													{/* <Link to={{ pathname: `/${lang}/app/asociados/dojo_${elem.codigo_dojo}` }}>
														<button className="flex-row-item no-wrap gap-small table-button">
															<ViewIcon className="tiny-column"/> */}
													<span className="grow text-ellipsis">
														{elem?.dojo}
													</span>
													{/* </button>
													</Link> */}
													{/* } */}
												</div>
												<div className="table-results--td wide-column">
													{elem?.adulto}
												</div>
												<div className="table-results--td wide-column">
													{elem?.ultima_cuota}
												</div>
											</div>
										))}
								</div>
							</div>
						) : null}
					</div>
					<div className="modal-footer">
						<div className="flex-row-item">
							<div className="flex-wrapper flex-end">
								<button className="button tertiary" onClick={cierra}>
									{loc("Cerrar")}
								</button>
							</div>
						</div>
					</div>
				</div>
				<CursoImagenMod
					show={verImagen}
					image={"/curso/" + Curso?.imagen}
					onHide={() => setVerImagen(false)}
				/>
			</main>
		);
};

export default InscripcionMod;
