import React, {useReducer, useCallback,useRef} from "react";

import ConexContext from "./ConexContext";
import ConexReducer from "./ConexReducer";

const ConexState = (props) => {
	const initialState = {
		token: null,
		locale: null,
		jwtSecu: null,
		perfil_id: null,
		Cargando:true,
	};
	const [state, dispatch] = useReducer(ConexReducer, {initialState});
	const config = require('../../config/config.json');
	//diccionario de idiomas
	const locEN = require('../../config/locale/en.json');
	const locFR = require('../../config/locale/fr.json');
	const locES = require('../../config/locale/es.json');
	
	const getToken = useRef(state.token)
	/**
	* lanza una peticion al microservicio
	* @param {string} url 
	*/
	const peticion = useCallback(async (url, {method='GET', headers={}, json=null}={})=>{
		if (json & method==='GET')
			return({
				mensaje:"Imposible mandar JSON con el método GET",
				estado:3
			})
		let data
		// console.log('getToken',getToken.current );
		try{
			let conexBack=config.conexBack
			let host=conexBack.host;
			let param={
				method: method,
				headers: headers
			}
			param.headers.authorization=getToken.current

			if (json){
				param.headers['Content-Type']='application/json'
				param['body']=JSON.stringify(json)
			}
			if (conexBack?.port??null) //si el puerto es 0 no se usa
				host+=':'+conexBack.port
			setCargando(true)
			data= await fetch(`${host}/${conexBack.app}/${conexBack.version}${url}`,param);
			setCargando(false)
		}
		catch {
			return({
				mensaje:"El microservicio no responde",
				estado:1
			})
		}
		if (data.status!==200)
			return({
				mensaje:`Problema de conexión. ${data.status}. ${data.statusText}. ${data.type}`,
				estado:2
			})
		data= await data.json();
		data=Object.assign({peticion: url}, data);
		// si el token hay que renovarlo o ha expirado nos devuelve otro-> lo guardamos
		if ('token' in data){
			setToken(data.token);
			// getToken.current=data.token
		}
		if (config.DebugMode)
			console.log(data)

		return (data)
	},[config])

	const peticionPDF = useCallback(async (url, {method='GET', headers={}, json=null}={})=>{
		if (json & method==='GET')
			return({
				mensaje:"Imposible mandar JSON con el método GET",
				estado:3
			})
		let data
		// console.log('getToken',getToken.current );
		try{
			let conexBack=config.conexBack
			let host=conexBack.host;
			let param={
				method: method,
				headers: headers
			}
			param.headers.authorization=getToken.current

			if (json){
				param.headers['Content-Type']='application/json'
				param['body']=JSON.stringify(json)
			}
			if (conexBack?.port??null) //si el puerto es 0 no se usa
				host+=':'+conexBack.port
			setCargando(true)
			data= await fetch(`${host}/${conexBack.app}/${conexBack.version}${url}`,param);
			setCargando(false)
		}
		catch {
			return({
				mensaje:"El microservicio no responde",
				estado:1
			})
		}
		
		let estado_aux=0
		try{
			if (data.status!==200)
				return({
					mensaje:`Problema de conexión. ${data.status}. ${data.statusText}. ${data.type}`,
					estado:2
				})
			let nombre_pdf=data.headers.get("Content-Disposition").split("filename=")[1]
			data = await data.blob();
			// Crear un enlace para descargar el archivo
			const fileURL = URL.createObjectURL(data);
			const link = document.createElement('a');
			link.href = fileURL;
			link.setAttribute('download', nombre_pdf); // Nombre del archivo
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link); // Limpiar el DOM
			return true;
		}
		catch{
			estado_aux=1
			return false;
		}
		finally{
			if (config.DebugMode)
				console.log({
					"estado": estado_aux,
					"mensaje": !estado_aux?"OK":"No se ha encontrado la plantilla",
					"peticion": "/peticionPDF"
				})
		}
	},[config])

	/**
	* devuelve la traducción de la palabra
	* @param {string} palabra
	*/
	const loc = (palabra)=>{
		//const loc = require('../../config/localeconfig.json');
		if (state.locale==='es')
			return(palabra in locES?locES[palabra]:palabra)
		else if (state.locale==='en')
			return(palabra in locEN?locEN[palabra]:palabra)
		else if (state.locale==='fr')
			return(palabra in locFR?locFR[palabra]:palabra)
		return(palabra);
	}

	const setToken = async (token) => {
		dispatch({ type: 'SET_JWT', payload: token});
		getToken.current=token
	};

	const setCargando=  async (val) => {
		dispatch({ type: 'SET_CARGANDO', payload: val});
	};
	
	const setLocale=useCallback( async (token) => {
		dispatch({ type: 'SET_LOCALE', payload: token});
	},[]);

	return (
		<ConexContext.Provider
			value={{
				token: state.token,
				locale: state.locale,
				perfil_id: state.perfil_id,
				perfil_admin: state.perfil_admin,
				perfil_adm_asoc: state.perfil_adm_asoc,
				perfil_adm_dojo: state.perfil_adm_dojo,
				perfil_dojo: state.perfil_dojo,
				perfil_asoc: state.perfil_asoc,
				perfil_avatar: state.perfil_avatar,
				Cargando: state.Cargando,
				
				setCargando,
				setToken,
				setLocale,
				peticion,
				peticionPDF,
				loc,
			}}
		>
			{props.children}
		</ConexContext.Provider>
	);
};

export default ConexState;