import React, {useState,useRef, useContext, useEffect} from 'react'
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams 
} from "react-router-dom";

import Swal from 'sweetalert2'

//Imagenes
import logo from '../assets/img/logo.jpg';
import user from '../assets/img/iconos/acae_user.svg';
// const avatars =require.context('https://aikidoacae.com/static/media',false,/\.jpg$/)

// import number from '../assets/img/number.svg';
import padlock from '../assets/img/padlock.svg';
import { ReactComponent as BackIcon } from '../assets/img/iconos/acae_back.svg';



import espanol from '../assets/img/espanol.png';
import ingles from '../assets/img/uk.png';
import frances from '../assets/img/frances.png';

import clickOut from '../assets/js/clickOut'
//Contexto
import ConexContext from "../context/conex/ConexContext";

const Login = () => {
	const [Mail, setMail] = useState("");
	// const [NACAE, setNACAE] = useState("");
	const [Psw, setPsw] = useState("");
	const [Psw2, setPsw2] = useState("");
	const [ValCode, setValCode] = useState(null);//Hay un valCode en principio válido. Con él validaremos la contraseña
	
	const [Error, setError] = useState("");
	const [OpLang, setOpLang] = useState(false);

	const [searchParams] = useSearchParams();

	//REFs
	const rMail = useRef();	
	// const rNACAE = useRef();
	const rPsw = useRef();
	const rPsw2 = useRef();
	const selLang = useRef();

	const { lang } = useParams();
	//contexto
	const {setLocale,locale,loc,peticion}=useContext(ConexContext)
	const navigate = useNavigate()
	const config= require('../config/config.json')

	//cierra el selec de lenguaje
	clickOut(selLang, () => {
		setOpLang(false)
	});
	
	async function login(event){
		event.preventDefault();
		const regMail=/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
		// const regNACAE=/^\d{2}\w\d{4}$/i
		if (Mail==='')
			setError('El correo es necesario.')
		// else if (NACAE==='')
		// 	setError('El número de ACAE es necesario.')
		else if (Psw==='')
			setError('La contraseña es necesaria.')
		else if (!regMail.test(Mail))
			setError('El correo es inválido.')
		// else if (!regNACAE.test(NACAE))
		// 	setError('El número de ACAE es inválido.')
		else{
			let res=await peticion('/login',{headers:{
				mail:Mail,
				// numAcae:NACAE,
				psw:Psw
			}})
			if (!res.res?.auth) setError('Las claves de acceso son erróneas.')
			if (!res.auth) setError('Error en la Base de Datos.')
		}
	}
	
	// async function preVal(mail, valCode) {//pasamos al modo validar contraseña, si es correcto
	// 	const regMail = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
	// 	if (!regMail.test(mail))
	// 		setError('El correo es inválido.')
	// 	else {
	// 		const pet = await peticion('/valida', {
	// 			headers: {
	// 				mail: mail,
	// 				valCode: valCode
	// 			}
	// 		})
	// 		if (pet.estado)//algún error 
	// 			return
	// 		if (!(pet?.res ?? false))//No es correcta la validación
	// 			return
	// 		setValCode(valCode)
	// 	}
	// }

	// funcion para cambio de contraseña
	async function cambiarPsw(event) {
		event.preventDefault()
		const regPsw = /^(?=(?:.*\d))(?=.*[A-Za-z])\S{6,}$/;

		if (Psw === '' || Psw2 === '')
			setError("Debes introducir una contraseña y su confirmación")
		else if (Psw !== Psw2)
			setError(loc("La contraseña y su confirmación no coinciden"))
		else if (!regPsw.test(Psw))
			setError(loc("La contraseña debe contener al menos 6 caracteres, incluyendo letras y números"))
		else {
			const params = Object.fromEntries([...searchParams]);//cojo todos los parámetros
			const pet = await peticion('/mod', {
				method: 'POST',
				json: {
					mail: rMail.current.value,
					psw: rPsw.current.value,
					clave: params.valCode
				}
			})
			if (pet?.estado??1) //algún error
				return
			else if (pet?.res?.result??false){ 
				Swal.fire({
					title: loc("Usuario validado."),
					text:(loc('Ahora puede acceder con su correo, su número de ACAE y la nueva contraseña.')),
					showConfirmButton: true,
					icon:'success'
				})	
				navigate(`/${lang}/app/?mail=${rMail.current.value}`)
			}
		}
	}

	//EFFECTS
	useEffect(() => {//cualquier cambio de los input
		setError('')
	}, [Mail,Psw])

	useEffect(() => {//lenguaje
		if (lang!==locale)// para evitar un bucle infinito
			setLocale(lang);
	}, [lang,locale,setLocale])	

	const tit_msg=loc("Usuario válido. Escriba su contraseña")
	const txt_msg=loc('Para poder acceder a la app de ACAE necesitarás una contraseña. Escribe tu contraseña y pincha en validar.')

	//hay cambio en los params de la URL (para validar usuario)
	useEffect(() => {//Aquí recojemos los parámetros tipo GET
		async function preVal(mail,valCode){//pasamos al modo validar contraseña, si es correcto
			Swal.fire({
				title: tit_msg,
				text:txt_msg,			
				showConfirmButton: true,
				icon:'info'
			})	
			const pet=await peticion('/valida',{headers:{
				mail:mail,
				valCode:valCode
			}})
			if (pet.estado)//algún error 
				return
			if (!(pet?.res??false))//No es correcta la validación
				return
			setValCode(valCode)
		}
		const params = Object.fromEntries([...searchParams]);//cojo todos los parámetros
		if (params?.mail??false){//hay un get de mail
			setMail(params.mail)
			// if (params?.numAcae??false)//tambien hay un número de ACAE
			// 	setNACAE(params.numAcae)
			setValCode(null)//por defecto no es correto
			if (params?.valCode??false)//tambien hay un validar
				preVal(params.mail,params.valCode)// comprobamos a priori si el mail y el valcode están bien
		}
	// eslint-disable-next-line
	}, [searchParams]);

if (!locale)
	return (null)
else
	return (
		<div id="wrapper">
			<div className="login">
				<Link to={`/${lang}/`} className='login-back'>
					<button className='unstyled-button flex-row-item gap-small'>
						<BackIcon /> <span>{loc("Volver")}</span>
					</button>
				</Link>
				<img src={logo} className="logo" alt="logo" />
					<form onSubmit={ValCode === null ? login : cambiarPsw}>
						<div className="arriba">
							<ul>
								<li>
									<input type="text" placeholder={loc('Correo')}
										ref={rMail}
										value={Mail}
										id='correo'
										onChange={() => setMail(rMail.current.value)} 
										autoComplete="email"
									/>
								</li>
								<li>
									<img src={user} alt='user' />
								</li>
							</ul>
						</div>
						{/* {ValCode===null &&
						<div className="arriba">
							<ul>
								<li>
									<img src={number} alt='number' />
								</li>
								<li>
									<input type="text" placeholder={loc('Número ACAE')} title='Tu nº de ACAE será de la forma NNANNNN'
										ref={rNACAE}
										id='num'
										value={NACAE}
										onChange={() => setNACAE(rNACAE.current.value)} />
								</li>
							</ul>
						</div>
						} */}
						<div className={ValCode?"arriba":"abajo"}>
							<ul>
								<li>
									<input type="password" placeholder="Contraseña"
										ref={rPsw}
										value={Psw}
										id='psw'
										onChange={() => setPsw(rPsw.current.value)} 
										autoComplete="current-password"
									/>
								</li>
								<li>
									<img src={padlock} alt='padlock' />
								</li>
							</ul>
						</div>
						{ValCode && 
						
						<div className="abajo">
							
							<ul>
								<li>
									<input type="password" placeholder="Repite contraseña"
										ref={rPsw2}
										value={Psw2}
										onChange={() => setPsw2(rPsw2.current.value)} />
								</li>
								<li>
									<img src={padlock} alt='padlock' />
								</li>
							</ul>
						</div>
						}
						<div className="error">
							<p className="error1">{Error}</p>
						</div>
						<button className="login-button" type="submit">{loc('Acceder')}</button>
					</form>
				{!config?.AyudaLogin??true?null:
					<div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
					<span style={{ cursor: 'pointer' }} onClick={() => { setMail('guirenedo@hotmail.com'); setPsw('123456') }}>Guillermo</span>
					<span style={{ cursor: 'pointer' }} onClick={() => { setMail('jomdonam@yahoo.es'); setPsw('123456') }}>Manuel</span>
					<span style={{ cursor: 'pointer' }} onClick={() => { setMail('troneja@gmail.com'); setPsw('123456') }}>Pablo</span>
					<span style={{ cursor: 'pointer' }} onClick={() => { setMail('sra00017@re.ujaen.es'); setPsw('123456a') }}>Sergio</span>
				</div>
				}


				
					<div className={`selectidiomas loginidiomas ${OpLang?'abierto':'cerrado'}`} onClick={()=>setOpLang(!OpLang)} ref={selLang} id="button">
						<h3><span>{lang.toUpperCase()}</span>{
						lang==='es' ?  <img src={espanol} alt=""/>
						:lang==='en'? <img src={ingles} alt=""/>
						:lang==='fr'? <img src={frances} alt=""/>
						:null
						}
						</h3>
						<input id="toggle" type="checkbox" />
							<div id="two">
								<ul>
									<li  className={lang==='es'?'selec':''}><button onClick={()=>navigate('/es/app')}><span>ES</span><img src={espanol} alt=""/></button></li>
									<li  className={lang==='en'?'selec':''}><button onClick={()=>navigate('/en/app')}><span>EN</span><img src={ingles} alt=""/></button></li>
									<li  className={lang==='fr'?'selec':''}><button onClick={()=>navigate('/fr/app')}><span>FR</span><img src={frances} alt=""/></button></li>
								</ul>	
							</div>
					</div>
			

			</div>
		</div>
	)
}

export default Login